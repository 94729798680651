import React from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'
import WishListContainer from '../../components/complex/WishListContainer'

const WishList = () => {
  return (
    <div>
        <Breadcrumb/>
        <WishListContainer/>
    </div>
  )
}

export default WishList