import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import Dashboard from './Dashboard'
import EditProfile from './EditProfile'
import Orders from './Orders'
import OrderDetail from './OrderDetail'
import AddressEdit from './AddressEdit'
import Password from './Password'
import Addresses from './Addresses'

const ProfileContainer = () => {
  return (
    <div className="block">
        <div className="container">
            <Tab.Container defaultActiveKey="tab-dashboard">
                <div className="row" >
                    <div className="col-12 col-lg-3 d-flex">
                        <div className="account-nav flex-grow-1">
                            <h4 className="account-nav__title">Навигация</h4>
                            <Nav>
                                <Nav.Item className="account-nav__item">
                                    <Nav.Link eventKey="tab-dashboard">Панель управления</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="account-nav__item ">
                                    <Nav.Link eventKey="tab-edit-profile">Редактировать профиль</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="account-nav__item ">
                                    <Nav.Link eventKey="tab-orders">История заказов</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="account-nav__item ">
                                    <Nav.Link eventKey="tab-order-detail">Детали заказа</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="account-nav__item ">
                                    <Nav.Link eventKey="tab-addresses">Адреса</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="account-nav__item ">
                                    <Nav.Link eventKey="tab-edit-address">Редактировать адрес</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="account-nav__item ">
                                    <Nav.Link eventKey="tab-password">Пароль</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <Tab.Content className="col-12 col-lg-9 mt-4 mt-lg-0">
                        <Tab.Pane eventKey="tab-dashboard">
                            <Dashboard/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-edit-profile">
                            <EditProfile/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-orders">
                            <Orders/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-order-detail">
                            <OrderDetail/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-addresses">
                            <Addresses/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-edit-address">
                            <AddressEdit/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-password">
                            <Password/>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    </div>
  )
}

export default ProfileContainer