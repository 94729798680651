import React from 'react'

const OrderDetail = () => {
  return (
    <>
      <div className="card">
        <div className="order-header">
            <div className="order-header__actions">
                <a href="account-orders.html" className="btn btn-xs btn-secondary">Обратно к списку</a>
            </div>
            <h5 className="order-header__title">Заказ #3857</h5>
            <div className="order-header__subtitle">
                Был размещен <mark className="order-header__date">19 Октября, 2020</mark>
                и в данный момент <mark className="order-header__status">на удержании</mark>.
            </div>
        </div>
        <div className="card-divider"></div>
        <div className="card-table">
            <div className="table-responsive-sm">
                <table>
                    <thead>
                        <tr>
                            <th>Продукт</th>
                            <th>Итог</th>
                        </tr>
                    </thead>
                    <tbody className="card-table__body card-table__body--merge-rows">
                        <tr>
                            <td>Электрический рубанок Brandix KL370090G 300 Watts × 2</td>
                            <td>$1,398.00</td>
                        </tr>
                        <tr>
                            <td>Неизвестный инструмент IRadix DPS3000SY 2700 watts × 1</td>
                            <td>$849.00</td>
                        </tr>
                        <tr>
                            <td>Brandix Инструмент питания маршрутизатора 2017ERXPK × 3</td>
                            <td>$3,630.00</td>
                        </tr>
                    </tbody>
                    <tbody className="card-table__body card-table__body--merge-rows">
                        <tr>
                            <th>Промежуточный итог</th>
                            <td>$5,877.00</td>
                        </tr>
                        <tr>
                            <th>Счёт в магазине</th>
                            <td>$-20.00</td>
                        </tr>
                        <tr>
                            <th>Доставка</th>
                            <td>$25.00</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Итог</th>
                            <td>$5,882.00</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
      </div>
      <div className="row mt-3 no-gutters mx-n2">
          <div className="col-sm-6 col-12 px-2">
              <div className="card address-card address-card--featured">
                  <div className="address-card__body">
                      <div className="address-card__badge address-card__badge--muted">Адрес доставки</div>
                      <div className="address-card__name">Хелен Гарсия</div>
                      <div className="address-card__row">
                          Рандом Федерация<br/>
                          115302, Москва<br/>
                          ул. Варшавская, 15-2-178
                      </div>
                      <div className="address-card__row">
                          <div className="address-card__row-title">Номер телефона</div>
                          <div className="address-card__row-content">38 972 588-42-36</div>
                      </div>
                      <div className="address-card__row">
                          <div className="address-card__row-title">Адрес электронной почты</div>
                          <div className="address-card__row-content">stroyka@example.com</div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
              <div className="card address-card address-card--featured">
                  <div className="address-card__body">
                      <div className="address-card__badge address-card__badge--muted">Адрес для выставления счёта</div>
                      <div className="address-card__name">Хелен Гарсия</div>
                      <div className="address-card__row">
                          Рандом Федерация<br/>
                          115302, Москва<br/>
                          ул. Варшавская, 15-2-178
                      </div>
                      <div className="address-card__row">
                          <div className="address-card__row-title">Номер телефона</div>
                          <div className="address-card__row-content">38 972 588-42-36</div>
                      </div>
                      <div className="address-card__row">
                          <div className="address-card__row-title">Адрес электронной почты</div>
                          <div className="address-card__row-content">stroyka@example.com</div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </>
  )
}

export default OrderDetail