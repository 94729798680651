import React from 'react'
import Pagination from './Pagination'
import ArticleSidebar from './ArticleSidebar'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import PostItem from '../simple/PostItem'

const ArticlesContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)


  return (
    <div className="container">
    <div className="row">
        <div className="col-12 col-lg-8">
            <div className="block">
                <div className="posts-view">
                    <div className="posts-view__list posts-list posts-list--layout--grid2">
                        <div className="posts-list__body">
                            {items?.items?.map((card, index) =>
                                <PostItem key={index} card={card}/>
                            )}
                        </div>
                    </div>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
        </div>
        <ArticleSidebar setFilterData={setFilterData}/>
    </div>
</div>
  )
}

export default ArticlesContainer