import React, { useContext, useEffect, useState } from 'react'
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import { useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import CatalogServices from '../../services/general/catalogServices';
import useFetchItems from '../../hooks/useFetchItems';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const DropDownSearch = observer(() => {
    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    const {app} = useContext(Context);

    const [term, setTerm] = useState('');
    const navigate = useNavigate();

    const {
        items,
        setFilterData
    } = useFetchItems(CatalogServices.products.index)

    const searchOn = () => {
        navigate(`/${app.lang}/catalog?term=${term}`);
        setIsActive(false)
    }

    const dynamicSearch = (e) => {
        setTerm(e.target.value);
        if(e.target.value.length >= 3){
            setFilterData(`?term=${term}`)
        }
        if(e.target.value.length <= 0){
            setFilterData(``)
        }
    }

    const navigateProduct = (card) => {
        navigate(`/${app.lang}/products/${card.slug}`)
        setIsActive(false)
    }

  return (
    <div className={isActive ? "indicator indicator--trigger--click indicator--display indicator--open" : "indicator indicator--trigger--click"} ref={dropdownRef}>
        <button type="button" className="indicator__button" onClick={() => setIsActive(!isActive)}>
            <span className="indicator__area">
                <i className='fa fa-search'></i>
            </span>
        </button>
        <div className="indicator__dropdown">
            <div className={isActive ? "search search--location--indicator search--suggestions-open search--has-suggestions" : "search search--location--indicator"}>
                <div className="search__body">
                    <div className="search__form">
                        <input className="search__input" placeholder={app.localizationsItems?.search_placeholder} value={term} onChange={(e) => dynamicSearch(e)} type="text" />
                        <button className="search__button search__button--type--submit" onClick={() => searchOn()}>
                            <i className='fa fa-search'></i>
                        </button>
                        <div className="search__border"></div>
                    </div>
                    <div className="search__suggestions suggestions suggestions--location--indicator">
                        <ul className='suggestions__list'>
                            {items?.items?.map((card, index) =>
                                <li className='suggestions__item' key={index} onClick={() => navigateProduct(card)}>
                                    <div className="suggestions__item-image product-image">
                                        <div className="product-image__body">
                                            <img className="product-image__img" src={card.image ? card.image : img} alt={card.title}
                                                title={card.title}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src = img
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="suggestions__item-info">
                                        <div className="suggestions__item-name">
                                            {card.title}
                                        </div>
                                        <div className="suggestions__item-meta">{card.subtitle}</div>
                                    </div>
                                    {card.old_price ?
                                        <div className="suggestions__item-price">
                                            <span className="product-card__new-price">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency} </span><br/>
                                            <span className="product-card__old-price">{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>
                                        </div>
                                        :
                                        <div className="suggestions__item-price">{card.price == 0 ? `${app.localizationsItems?.not_specified}` : <>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</>}</div>
                                    }
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default DropDownSearch