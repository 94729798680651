import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MainBanner from '../../components/complex/MainBanner';
import Advantages from '../../components/complex/Advantages';
import Advantages2 from '../../components/complex/Advantages2';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import RelatedProducts from '../../components/complex/RelatedProducts';
import Blog from '../../components/complex/Blog';
import OfferGrid from '../../components/complex/OfferGrid';
import FaqComponent from '../../components/complex/FaqComponent';
import FormContact from '../../components/complex/FormContact';
import Reviews from '../../components/complex/Reviews';
import Partners from '../../components/complex/Partners';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import Workers from '../../components/complex/Workers';
import TextComponent from '../../components/complex/TextComponent';

const SamplePage = observer(() => {
    const {slug} = useParams();
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/page/${slug}`)
            }
        }
    }, [app.lang])

    
    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

  return (
    <>
    {loading ?
        <div className='isLoading'></div>
    :
        <div className="block about-us">
            <Helmet>
                <title>{items.seo_title ? items.seo_title : items.title}</title>
                {items.seo_description && <meta name="description" content={items.seo_description} />}
                {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
            </Helmet>
            <div className="about-us__image">
                <img src={items.detail_image} alt={items.title} title={items.title} />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">{items.title}</h1>
                            <div className="typography"
                                dangerouslySetInnerHTML={{ __html: items.description }}
                            ></div>
            {
                items.elements?.map((elem, index) =>
                    <div key={index}>
                        {
                            elem.type === "opening-slider" ?
                                <MainBanner elem={elem}/>
                            :
                            elem.type === "advantages" ?
                                <Advantages elem={elem}/>
                            :
                            elem.type === "advantages-2" ?
                                <Advantages2 elem={elem}/>
                            :
                            elem.type === "advertising-banner-2" ?
                                <AdvertisingBanner elem={elem}/>
                            :
                            elem.type === "products" ?
                                <RelatedProducts elem={elem}/>
                            :
                            elem.type === "articles" ?
                                <Blog elem={elem}/>
                            :
                            elem.type === "products-mix" ?
                                <OfferGrid/>
                            :
                            elem.type === "faq" ?
                                <FaqComponent item={elem}/>
                            :
                            elem.type === "form" ?
                                <FormContact item={elem}/>
                            :
                            elem.type === "reviews" ?
                                <Reviews item={elem}/>
                            :
                            elem.type === "partners" ?
                                <Partners item={elem}/>
                            :
                            elem.type === "workers" ?
                                <Workers item={elem}/>
                            :
                            elem.type === "text-information" ?
                                <TextComponent item={elem}/>
                            :
                            <></>
                        }
                    </div>    
                )
            }
                                    </div>
                    </div>
                </div>
            </div>
        </div>}
    </>
  )
})

export default SamplePage