import React from 'react'

const WishListTr = () => {
  return (
    <tr className="wishlist__row">
        <td className="wishlist__column wishlist__column--image">
            <div className="product-image">
                <a href="" className="product-image__body">
                    <img className="product-image__img" src="" alt=""/>
                </a>
            </div>
        </td>
        <td className="wishlist__column wishlist__column--product">
            <a href="" className="wishlist__product-name">Электрический рубанок Brandix KL370090G 300 Watts</a>
            <div className="wishlist__product-rating">
                <div className="rating">
                    <div className="rating__body">
                        <svg className="rating__star rating__star--active" width="13px" height="12px">
                            <g className="rating__fill">
                                <use href="images/sprite.svg#star-normal"></use>
                            </g>
                            <g className="rating__stroke">
                                <use href="images/sprite.svg#star-normal-stroke"></use>
                            </g>
                        </svg>
                        <div className="rating__star rating__star--only-edge rating__star--active">
                            <div className="rating__fill">
                                <div className="fake-svg-icon"></div>
                            </div>
                            <div className="rating__stroke">
                                <div className="fake-svg-icon"></div>
                            </div>
                        </div>
                        <svg className="rating__star rating__star--active" width="13px" height="12px">
                            <g className="rating__fill">
                                <use href="images/sprite.svg#star-normal"></use>
                            </g>
                            <g className="rating__stroke">
                                <use href="images/sprite.svg#star-normal-stroke"></use>
                            </g>
                        </svg>
                        <div className="rating__star rating__star--only-edge rating__star--active">
                            <div className="rating__fill">
                                <div className="fake-svg-icon"></div>
                            </div>
                            <div className="rating__stroke">
                                <div className="fake-svg-icon"></div>
                            </div>
                        </div>
                        <svg className="rating__star rating__star--active" width="13px" height="12px">
                            <g className="rating__fill">
                                <use href="images/sprite.svg#star-normal"></use>
                            </g>
                            <g className="rating__stroke">
                                <use href="images/sprite.svg#star-normal-stroke"></use>
                            </g>
                        </svg>
                        <div className="rating__star rating__star--only-edge rating__star--active">
                            <div className="rating__fill">
                                <div className="fake-svg-icon"></div>
                            </div>
                            <div className="rating__stroke">
                                <div className="fake-svg-icon"></div>
                            </div>
                        </div>
                        <svg className="rating__star rating__star--active" width="13px" height="12px">
                            <g className="rating__fill">
                                <use href="images/sprite.svg#star-normal"></use>
                            </g>
                            <g className="rating__stroke">
                                <use href="images/sprite.svg#star-normal-stroke"></use>
                            </g>
                        </svg>
                        <div className="rating__star rating__star--only-edge rating__star--active">
                            <div className="rating__fill">
                                <div className="fake-svg-icon"></div>
                            </div>
                            <div className="rating__stroke">
                                <div className="fake-svg-icon"></div>
                            </div>
                        </div>
                        <svg className="rating__star " width="13px" height="12px">
                            <g className="rating__fill">
                                <use href="images/sprite.svg#star-normal"></use>
                            </g>
                            <g className="rating__stroke">
                                <use href="images/sprite.svg#star-normal-stroke"></use>
                            </g>
                        </svg>
                        <div className="rating__star rating__star--only-edge ">
                            <div className="rating__fill">
                                <div className="fake-svg-icon"></div>
                            </div>
                            <div className="rating__stroke">
                                <div className="fake-svg-icon"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wishlist__product-rating-legend">
                    9 отзывов
                </div>
            </div>
        </td>
        <td className="wishlist__column wishlist__column--stock">
            <div className="badge badge-success">В наличии</div>
        </td>
        <td className="wishlist__column wishlist__column--price">$699.00</td>
        <td className="wishlist__column wishlist__column--tocart">
            <button type="button" className="btn btn-primary btn-sm">Добавить в корзину</button>
        </td>
        <td className="wishlist__column wishlist__column--remove">
            <button type="button" className="btn btn-light btn-sm btn-svg-icon">
                <svg width="12px" height="12px">
                    <use href="images/sprite.svg#cross-12"></use>
                </svg>
            </button>
        </td>
    </tr>
  )
}

export default WishListTr