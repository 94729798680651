import React from 'react'

const EditProfile = () => {
  return (
    <div className="card">
        <div className="card-header">
            <h5>Редактировать профиль</h5>
        </div>
        <div className="card-divider"></div>
        <div className="card-body">
            <div className="row no-gutters">
                <div className="col-12 col-lg-7 col-xl-6">
                    <div className="form-group">
                        <label >Имя</label>
                        <input type="text" className="form-control" id="profile-first-name" placeholder="Введите имя"/>
                    </div>
                    <div className="form-group">
                        <label >Фамилия</label>
                        <input type="text" className="form-control" id="profile-last-name" placeholder="Введите фамилию"/>
                    </div>
                    <div className="form-group">
                        <label >Адрес электронной почты</label>
                        <input type="email" className="form-control" id="profile-email" placeholder="Введите адрес электронной почты"/>
                    </div>
                    <div className="form-group">
                        <label >Номер телефона</label>
                        <input type="text" className="form-control" id="profile-phone" placeholder="Введите номер телефона"/>
                    </div>
                    <div className="form-group mt-5 mb-0">
                        <button className="btn btn-primary">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditProfile