import React, { useContext} from 'react';
import {Route, Routes} from 'react-router-dom'
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import { privateRoutes, publicRoutes } from './Routes';
import Error from '../pages/error/Error';

const AppRouter = observer(() => {
    const {user} = useContext(Context)

    return (
        <Routes>
            {user.token && privateRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={
                    <Component></Component>
                } exact/>
            )}
            {publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={
                    <Component></Component>
                } exact/>
            )}
            <Route path="*" element={<Error />} />
        </Routes>
    );
});

export default AppRouter;