import React from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import AuthServices from '../../services/general/authServices';

const Registration = () => {
    const navigate = useNavigate();
	const {handleSubmit, register, getValues, setValue, control, formState: {errors}} = useForm();

	const CustomSubmit = async (data) => {
        const res = await AuthServices.register(data);

        if (res.statusCode === 200) {
			navigate('/');
        }else{
			console.log(res)
		}
    }


  return (
    <div>
        <Breadcrumb/>
        <div className="block">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 d-flex flex-column mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Регистрация</h3>
                                    <form onSubmit={handleSubmit(CustomSubmit)}>
                                        <div className="form-group">
                                            <label>ФИО*</label>
                                            <input type="text" {...register('name')} required className="form-control" placeholder="Введите адрес электронной почты"/>
                                        </div>
                                        <div className="form-group">
                                            <label>Адрес электронной почты*</label>
                                            <input type="email" {...register('email')} required className="form-control" placeholder="Введите адрес электронной почты"/>
                                        </div>
                                        <div className="form-group">
                                            <label>Пароль*</label>
                                            <input type="password" {...register('password')} required className="form-control" placeholder="Введите пароль"/>
                                        </div>
                                        <div className="form-group">
                                            <label>Повторите пароль*</label>
                                            <input type="password" {...register('password_confirmation')} required className="form-control" placeholder="Введите пароль"/>
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-4">Зарегистрироваться</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Registration