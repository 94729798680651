import React, { useContext } from 'react'
import MobMenuItem from '../simple/MobMenuItem'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const MobileMenu = observer(({open, setOpen, items}) => {
    const {app} = useContext(Context);
    const navigate = useNavigate();

    const close = () => {
        setOpen(false)
    }

    const changeNavigate = (value) => {
        navigate(value);
        setOpen(false)
    }

  return (
    <div className={open ? "mobilemenu mobilemenu--open" : "mobilemenu"}>
        <div className="mobilemenu__backdrop" onClick={close}></div>
        <div className="mobilemenu__body">
            <div className="mobilemenu__header">
                <div className="mobilemenu__title">{app.localizationsItems?.menu}</div>
                <button type="button" className="mobilemenu__close" onClick={close}>
                    <i className='fa fa-times'></i>
                </button>
            </div>
            <div className="mobilemenu__content">
                <ul className="mobile-links mobile-links--level--0">
                    {items?.map((item, index) =>
                        <MobMenuItem key={index} item={item} changeNavigate={changeNavigate}/>
                    )}
                </ul>
            </div>
        </div>
    </div>
  )
})

export default MobileMenu