import http from "./http-common"
import {getData, postData} from "../handlers";

export default class AppServices {
    static settings = () => {
        return getData(http, `/app/system/settings`);
    }

    static localizations = () => {
        return getData(http, `/app/system/localizations`);
    }

    
    static menu = (menuName, queryString) => {
        return getData(http, `/app/system/menu/${menuName}`);
    }

    static page = (pageName) => {
        return getData(http, `/app/pages/${pageName}`);
    }

    static request = (data) => {
        return postData(http, `/app/system/request/template`, data)
    }

    static requestTemplate = (data) => {
        return postData(http, `/app/system/request/template`, data)
    }
}