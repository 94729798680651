import React, { useContext } from 'react'
import { useRef } from 'react';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { MAIN_PAGE_ROUTE, REGISTRATION_PAGE_ROUTE, USER_PAGE_ROUTE } from '../../navigation/Constants';
import AuthServices from '../../services/general/authServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const DropdownUser = observer(() => {
    const {user} = useContext(Context);

    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    const navigate = useNavigate();

    const {handleSubmit, register, getValues, setValue, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        const res = await AuthServices.logIn(data);

        if (res.statusCode === 200) {
            user.setUser(res.content);
            user.setToken(res.content.token);
			navigate(MAIN_PAGE_ROUTE);
        }
    }


  return (
    <div className={isActive ? "indicator indicator--trigger--click indicator--display indicator--open" : "indicator indicator--trigger--click"} ref={dropdownRef}>
        <div className="indicator__button" onClick={() => setIsActive(!isActive)}>
            <span className="indicator__area">
                <i className='fa fa-user'></i>
            </span>
        </div>
        <div className="indicator__dropdown">
            {user?.token ?
                <div className="account-menu">
                        <div className="account-menu__divider"></div>
                        <a href="account-dashboard.html" className="account-menu__user">
                            <div className="account-menu__user-avatar">
                                <img src="images/avatars/avatar-3.jpg" alt=""/>
                            </div>
                            <div className="account-menu__user-info">
                                <div className="account-menu__user-name">Хелен Гарсия</div>
                                <div className="account-menu__user-email">stroyka@example.com</div>
                            </div>
                        </a>
                        <div className="account-menu__divider"></div>
                        <ul className="account-menu__links">
                            <li><NavLink to={USER_PAGE_ROUTE}>Редактировать профиль</NavLink></li>
                            <li><NavLink to={USER_PAGE_ROUTE}>История заказов</NavLink></li>
                            <li><NavLink to={USER_PAGE_ROUTE}>Адреса</NavLink></li>
                            <li><NavLink to={USER_PAGE_ROUTE}>Пароль</NavLink></li>
                        </ul>
                        <div className="account-menu__divider"></div>
                        <ul className="account-menu__links">
                            <li><a href="account-login.html">Выйти</a></li>
                        </ul>
                </div>
                    :
                <div className="account-menu">
                    <form className="account-menu__form" onSubmit={handleSubmit(CustomSubmit)}>
                        <div className="account-menu__form-title">Войти в аккаунт</div>
                        <div className="form-group">
                            <label className="sr-only">Email адрес</label>
                            <input {...register('email')} type="email" className="form-control form-control-sm" placeholder="Адрес электронной почты"/>
                        </div>
                        <div className="form-group">
                            <label className="sr-only">Пароль</label>
                            <div className="account-menu__form-forgot">
                                <input {...register('password')} type="password" className="form-control form-control-sm" placeholder="Пароль"/>
                                <a href="" className="account-menu__form-forgot-link">Забыли пароль?</a>
                            </div>
                        </div>
                        <div className="form-group account-menu__form-button">
                            <button type="submit" className="btn btn-primary btn-sm">Войти</button>
                        </div>
                        <div className="account-menu__form-link">
                            <NavLink to={REGISTRATION_PAGE_ROUTE}>Создать аккаунт</NavLink>
                        </div>
                    </form>
                </div>
            }
        </div>
    </div>
  )
})

export default DropdownUser