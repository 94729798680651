import React, { useContext } from 'react'
import ProductSlider from './ProductSlider'
import { Nav, Tab } from 'react-bootstrap'
import AddToCart from './AddToCart';
import Utils from '../../services/utils';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const ProductContainer = observer(({info}) => {
    const {app} = useContext(Context);

  return (
    <div className="block">
        <div className="container back-white">
            <div className="product product--layout--standard" data-layout="standard">
                <div className="product__content">
                    <ProductSlider slider={info.gallery} title={info.title}/>
                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <button type="button" className="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Wishlist">
                                <i className='fa fa-heart'></i>
                            </button>
                        </div>
                        <h1 className="product__name">{info.title}</h1>
                        <div className="product__description">
                            {info.subtitle}
                        </div>
                        <ul className="product__meta">
                            <li className="product__meta-availability">
                                {app.localizationsItems?.availability}: {
                                    info?.availability ?
                                        <span className="text-success">{app.localizationsItems?.in_stock}</span>
                                    :
                                        <span className="text-danger">{app.localizationsItems?.not_available}</span>
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="product__sidebar">
                        <div className="product__prices">
                            {info.old_price ?
                                <>
                                    <span className="product-card__new-price">{Utils.numberWithSpaces(info.price)} {app.localizationsItems?.currency} </span>
                                    <span className="product-card__old-price">{Utils.numberWithSpaces(info.old_price)} {app.localizationsItems?.currency}</span>
                                </>
                                :
                                <>{info.price == 0 ? `${app.localizationsItems?.not_specified}` : <>{Utils.numberWithSpaces(info.price)} {app.localizationsItems?.currency}</>}</>
                            }
                        </div>
                        <AddToCart card={info}/>
                    </div>
                </div>
            </div>
            <Tab.Container className="product-tabs product-tabs--sticky" defaultActiveKey="tab-description">
                <div className="product-tabs__list">
                    <div className="product-tabs__list-body">
                        <Nav className="product-tabs__list-container container">
                            <Nav.Item>
                                <Nav.Link eventKey="tab-description" className="product-tabs__item">{app.localizationsItems?.description}</Nav.Link>
                            </Nav.Item>
                            {info?.features && <Nav.Item>
                                <Nav.Link eventKey="tab-specification" className="product-tabs__item">{app.localizationsItems?.features}</Nav.Link>
                            </Nav.Item>}
                        </Nav>
                    </div>
                </div>
                <Tab.Content className="product-tabs__content">
                    <Tab.Pane className="product-tabs__pane" eventKey="tab-description">
                        <div className="typography" dangerouslySetInnerHTML={{ __html: info.description }}/>
                    </Tab.Pane>
                    {info?.features && <Tab.Pane className="product-tabs__pane" eventKey="tab-specification">
                        <div className="spec">
                            <h3 className="spec__header">{app.localizationsItems?.features}</h3>
                            {info.features &&
                                <div className="spec__section">
                                    {info.features.map((elem, index) =>
                                        <div className="spec__row" key={index}>
                                            <div className="spec__name">{elem.title}</div>
                                            <div className="spec__value">{elem.value}</div>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </Tab.Pane>}
                </Tab.Content>
            </Tab.Container>
        </div>
    </div>
  )
})

export default ProductContainer