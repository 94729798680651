import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const СonsultationModal = observer(({show, setShow, productName}) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const {app} = useContext(Context);

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const handleClose = () => setShow(false);

    const CustomSubmit = async (data) => {
        setLoading(true);

        const res = await AppServices.requestTemplate(data);
    
        if (res.statusCode === 200) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                handleClose();
            }, 3000);
        }
        setLoading(false);
    };

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <form className='checkout-form' onSubmit={handleSubmit(CustomSubmit)}>
                <div className="text-center">
                    <h2>{app.localizationsItems?.consultation}</h2>
                </div>
                <div className="form-group">
                    <label>{app.localizationsItems?.name}</label>
                    <input {...register('name')} required type="text" className="form-control" placeholder={app.localizationsItems?.name_placeholder}/>
                </div>
                <div className="form-group">
                    <label>{app.localizationsItems?.phone}</label>
                    <input  {...register('phone')} required type="text" className="form-control" placeholder={app.localizationsItems?.phone_placeholder}/>
                </div>
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <div className="form-group mb-0">
                            <button className="btn btn-primary" type='submit'>{app.localizationsItems?.consultation}</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className={visible ? 'form-success form-visible' : 'form-success'}>
                <i className='fa fa-check-circle'></i>
                <h2>{app.localizationsItems?.form_success_title}</h2>
                <p>{app.localizationsItems?.form_success_text}</p>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default СonsultationModal