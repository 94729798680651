import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import AppServices from "../../services/general/appServices";
import useFetchItems from '../../hooks/useFetchItems'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import MainBanner from '../../components/complex/MainBanner'
import Advantages from '../../components/complex/Advantages'
import RelatedProducts from '../../components/complex/RelatedProducts'
import Blog from '../../components/complex/Blog'
import AdvertisingBanner from '../../components/complex/AdvertisingBanner'
import OfferGrid from '../../components/complex/OfferGrid'
import FaqComponent from '../../components/complex/FaqComponent'
import FormContact from '../../components/complex/FormContact'
import Reviews from '../../components/complex/Reviews'
import Partners from '../../components/complex/Partners'
import Advantages2 from '../../components/complex/Advantages2'

const MainPage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    /*useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}`);
            }
        }
    }, [app.lang])*/

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')

  return (
    <>
    {loading ?
    <div className='isLoading'></div>
    :
    <div>
        <Helmet>
            <title>{items.seo_title ? items.seo_title : items.title}</title>
            {items.seo_description && <meta name="description" content={items.seo_description} />}
            {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
        </Helmet>
        {
            items.elements?.map((elem, index) =>
                <div key={index}>
                    {
                        elem.type === "opening-slider" ?
                            <MainBanner elem={elem}/>
                        :
                        elem.type === "advantages" ?
                            <Advantages elem={elem}/>
                        :
                        elem.type === "advantages-2" ?
                            <Advantages2 elem={elem}/>
                        :
                        elem.type === "advertising-banner-2" ?
                            <AdvertisingBanner elem={elem}/>
                        :
                        elem.type === "products" ?
                            <RelatedProducts elem={elem}/>
                        :
                        elem.type === "articles" ?
                            <Blog elem={elem}/>
                        :
                        elem.type === "products-mix" ?
                            <OfferGrid/>
                        :
                        elem.type === "faq" ?
                            <FaqComponent item={elem}/>
                        :
                        elem.type === "form" ?
                            <FormContact item={elem}/>
                        :
                        elem.type === "reviews" ?
                            <Reviews item={elem}/>
                        :
                        elem.type === "partners" ?
                            <Partners item={elem}/>
                        :
                        <></>
                    }
                </div>    
            )
        }
    </div>}
    </>
  )
})

export default MainPage