import React, { useState } from 'react'
import ProductCard from '../simple/ProductCard'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import { useLocation, useNavigate } from 'react-router-dom';

const RelatedProducts = ({elem}) => {
    const [category, setCategory] = useState();
    const [reset, setReset] = useState(true);
    const navigate = useNavigate();
    let query = new URLSearchParams(useLocation().search);

    const {
        items,
        loading,
        params, 
        setFilterData
    } = useFetchItems(CatalogServices.products.index, null, `type=${elem.products_type}&`)

    const {
        items:categories
    } = useFetchItems(CatalogServices.categories.index)

    const settings = {
        margin: 14,
        nav: true,
        dots: false,
        loop: true,
        navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
        stagePadding: 1,
        responsive: {
            0: {
                items: 2,
            },
            768:  {
                items: 3, 
                margin: 10
            },
            992:  {
                items: 4, 
                margin: 10
            },
            1200: {
                items: 5, 
                margin: 12
            },
        }
    }

    const changeCategory = (name) => {
        setReset(false);
        setCategory(name);
        setFilterData(`?categories[]=${name}`);
        navigate({
            search: `?categories[]=${name}`
        });
    }

    const resetCategories = () => {
        setReset(true);
        setCategory(null)
        setFilterData('');
        navigate({
            search: ``
        });
    }

  return (
    <div className="block block-products-carousel" data-layout="grid-5" data-mobile-grid-columns="2">
        <div className="container">
            <div className="block-header">
                <h3 className="block-header__title">{elem.title}</h3>
                <div className="block-header__divider"></div>
                <ul className="block-header__groups-list">
                    <li><button type="button" className={!query.get('categories[]') ? "block-header__group  block-header__group--active" : "block-header__group"} onClick={resetCategories}>Все</button></li>
                    {categories?.items?.slice(0,3).map((item, index) =>
                        <li key={index}><button type="button" className={query.get('categories[]') === item.slug ? "block-header__group  block-header__group--active" : "block-header__group" } onClick={() => changeCategory(item.slug)}>{item.title}</button></li>
                    )}
                </ul>
                <div className="block-header__arrows-list">
                </div>
            </div>
            {loading ?
            <></>
            :
            <div className="block-products-carousel__slider">
                <div className="block-products-carousel__preloader"></div>
                {items?.items &&
                    <OwlCarousel className="owl-carousel" {...settings} >
                        {items.items.map((card, index) =>
                            <div className="block-products-carousel__cell" key={index}>
                                <ProductCard card={card}/>
                            </div>
                        )}
                    </OwlCarousel>
                }
            </div>}
        </div>
    </div>
  )
}

export default RelatedProducts
