import React from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'
import FaqContainer from '../../components/complex/FaqContainer'

const FAQ = () => {
  return (
    <div>
        <Breadcrumb/>
        <FaqContainer/>
    </div>
  )
}

export default FAQ