import React, { useContext, useState } from 'react'
import BasketTr from '../simple/BasketTr'
import Utils from '../../services/utils';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const BasketContainer = observer(() => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();
    const navigate = useNavigate();

  return (
    <div className="cart block">
        <div className="container">
            <table className="cart__table cart-table">
                <thead className="cart-table__head">
                    <tr className="cart-table__row">
                        <th className="cart-table__column cart-table__column--image">{app.localizationsItems?.total}</th>
                        <th className="cart-table__column cart-table__column--product">{app.localizationsItems?.product}</th>
                        <th className="cart-table__column cart-table__column--price">{app.localizationsItems?.price}</th>
                        <th className="cart-table__column cart-table__column--quantity">{app.localizationsItems?.quantity}</th>
                        <th className="cart-table__column cart-table__column--total">{app.localizationsItems?.total}</th>
                        <th className="cart-table__column cart-table__column--remove"></th>
                    </tr>
                </thead>
                <tbody className={loading ? "cart-table__body isLoading" : "cart-table__body"}>
                    {user.cartProducts?.map((product, index) =>
                        <BasketTr product={product} key={index} setLoading={setLoading}/>
                    )}
                </tbody>
            </table>
            <div className="row justify-content-end pt-5">
                <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">{app.localizationsItems?.total}</h3>
                            <table className="cart__totals">
                                <tfoot className="cart__totals-footer">
                                    <tr>
                                        <th>{app.localizationsItems?.total}</th>
                                        <td>{Utils.numberWithSpaces(user.totalSum)} {app.localizationsItems?.currency}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <button disabled={user?.cartProducts?.length > 0 ? false : true} 
                                className="btn btn-primary btn-xl btn-block cart__checkout-button" onClick={() => navigate(`/${app.lang}/checkout`)}
                                >{app.localizationsItems?.checkout}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default BasketContainer