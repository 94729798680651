import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Breadcrumb = observer(({item}) => {
    const {app} = useContext(Context);

  return (
    <div className="page-header">
        <div className="page-header__container container">
            <div className="page-header__breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink to={`/${app.lang}`}>{app.localizationsItems?.main_page}</NavLink>
                            <i className='fa fa-chevron-right breadcrumb-arrow'></i>
                        </li>
                        {item.parent && <li className="breadcrumb-item">
                            <NavLink to={`/${app.lang}/catalog/${item.parent.slug}`}>{item.parent.title}</NavLink>
                            <i className='fa fa-chevron-right breadcrumb-arrow'></i>
                        </li>}
                        <li className="breadcrumb-item active" aria-current="page">{item?.title}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
  )
})

export default Breadcrumb