import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import AppRouter from './navigation/AppRouter';
import AppServices from './services/general/appServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n/i18n'
import './App.scss';
import Header from './components/complex/Header';
import Footer from './components/complex/Footer';
import ScrollToTop from './hooks/scrollToTop';

const App = observer(() => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const fetchdata = async () => {
        setLoading(true);
        const res = await AppServices.settings();
    
        if(res.statusCode === 200){
            let settings = res.content;
            app.setSettings(settings);
            app.setUserCabinet(settings.find(it => it.type === "user-enabled")?.value);
            app.setFiltermulti(settings.find(it => it.type === "categories-filter-multi")?.value);

            let color = settings.find(it => it.type === "color-primary")?.value;
            let bodyColor = settings.find(it => it.type === "body-сolor")?.value;
            let accentColor = settings.find(it => it.type === "accent-color")?.value;
            let accentColorHover = settings.find(it => it.type === "accent-color-hover")?.value;
            let buttonColor = settings.find(it => it.type === "button-color")?.value;
            let buttonHoverColor = settings.find(it => it.type === "button-hover-color")?.value;
            let iconColor = settings.find(it => it.type === "icon-font-color")?.value;
            let headerColor = settings.find(it => it.type === "header-color")?.value;
            let headerTopColor = settings.find(it => it.type === "header-top-color")?.value;
            let menuFontColor = settings.find(it => it.type === "menu-font-color")?.value;
            let headerTopFontColor = settings.find(it => it.type === "header-top-font-color")?.value;
            let footerColor = settings.find(it => it.type === "footer-color")?.value;
            let footerFontColor = settings.find(it => it.type === "footer-font-color")?.value;
            let buttonFontColor = settings.find(it => it.type === "button-font-color")?.value;
            let faqColor = settings.find(it => it.type === "faq-color")?.value;

            let head = settings.find(it => it.type === "head")?.value;
            let bottom = settings.find(it => it.type === "bottom")?.value;
            let header = settings.find(it => it.type === "header")?.value;

            color && document.documentElement.style.setProperty('--mainColor', color);
            accentColor && document.documentElement.style.setProperty('--accentColor', accentColor);
            bodyColor && document.documentElement.style.setProperty('--bodyColor', bodyColor);
            accentColorHover && document.documentElement.style.setProperty('--accentColorHover', accentColorHover);
            iconColor && document.documentElement.style.setProperty('--iconColor', iconColor);
            buttonColor && document.documentElement.style.setProperty('--buttonColor', buttonColor);
            headerColor && document.documentElement.style.setProperty('--headerColor', headerColor);
            headerTopColor && document.documentElement.style.setProperty('--headerTopColor', headerTopColor);
            buttonHoverColor && document.documentElement.style.setProperty('--buttonHoverColor', buttonHoverColor);
            menuFontColor && document.documentElement.style.setProperty('--menuFontColor', menuFontColor);
            headerTopFontColor && document.documentElement.style.setProperty('--headerTopFontColor', headerTopFontColor);
            footerColor && document.documentElement.style.setProperty('--footerColor', footerColor);
            footerFontColor && document.documentElement.style.setProperty('--footerFontColor', footerFontColor);
            buttonFontColor && document.documentElement.style.setProperty('--buttonFontColor', buttonFontColor);
            faqColor && document.documentElement.style.setProperty('--faqColor', faqColor);
            
            if(head){
                head.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.head.append(divFragment);
                });
            }

            if(header){
                header.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.append(divFragment);
                });
            }

            if(bottom){
                bottom.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.prepend(divFragment);
                });
            }
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            let temp;
            if(res2.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res2.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            app.setLang(temp)
            app.setLocalizationsItems(res2.content.find(it => it.slug === temp).description)
            app.setLanguages(res2.content)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchdata()
    }, [app.lang])

  return (
    <>{loading ?
        <div className='isLoading2'></div>
        :
        <BrowserRouter>
            <ScrollToTop />
            <Header/>
            <AppRouter/>
            <Footer/>
        </BrowserRouter>
    }
    </>
  )
})

export default App