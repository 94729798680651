import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import ProductSlider from '../complex/ProductSlider';
import AddToCart from '../complex/AddToCart';
import Utils from '../../services/utils';
import { NavLink } from 'react-router-dom';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const QuickViewModal = observer(({show, setShow, slug}) => {
    const handleClose = () => setShow(false);
    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);
    
  return (
    <Modal show={show} onHide={handleClose} centered size='xl'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className='product__content'>
                {info?.gallery && <ProductSlider slider={info.gallery}/>}
                <div className="product__info">
                    <NavLink to={`${app.lang}/products/${slug}`}>
                        <h1 className="product__name">{info?.title}</h1>
                    </NavLink>
                    <div className="product-card__availability mb-2">
                        {app.localizationsItems?.availability}: {
                                info?.availability ?
                                    <span className="text-success">{app.localizationsItems?.in_stock}</span>
                                :
                                    <span className="text-danger">{app.localizationsItems?.not_available}</span>
                        }
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: info?.subtitle }}></div>
                    <div className='product__prices'>
                        {info?.old_price ?
                            <>
                                <span className="product-card__new-price">{Utils.numberWithSpaces(info?.price)} {app.localizationsItems?.currency} </span>
                                <span className="product-card__old-price">{Utils.numberWithSpaces(info.old_price)} {app.localizationsItems?.currency}</span>
                            </>
                            :
                            <>{info?.price == 0 ? `${app.localizationsItems?.not_specified}` : <>{Utils.numberWithSpaces(info?.price)} {app.localizationsItems?.currency}</>}</>
                        }
                    </div>
                    {info?.features &&
                        <div>
                            <ul>
                                {info.features.map((elem, index) =>
                                    <li key={index}>{elem.title}: {elem.value}</li>
                                )}
                            </ul>
                        </div>
                    }
                    <AddToCart card={info}/>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default QuickViewModal