import React from 'react'
import img from "../../images/placeholder.png"

const Workers = ({item}) => {
  return (
    <div className="container">
            <div className="about-us__team">
                <h2 className="about-us__team-title">{item.title}</h2>
                <div className="about-us__team-subtitle text-muted" dangerouslySetInnerHTML={{__html: item.description}}></div>
                <div className="about-us__teammates teammates">
                    <div className="row">
                        {item.elements?.map((card, index) =>
                            <div className="col-sm-6 col-lg-4 teammates__item teammate" key={index}>
                                <div className="teammate__avatar">
                                    <img src={card.image ? card.image : img} alt={card.name} title={card.title}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
                                </div>
                                <div className="teammate__name">{card.name}</div>
                                <div className="teammate__position text-muted">{card.position}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Workers