import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const LatestItem = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="widget-products__item">
        <div className="widget-products__image">
            <div className="product-image">
                <NavLink to={`${app.lang}/products/${card.slug}`} className="product-image__body">
                    <img className="product-image__img" src={card.image ? card.image : img}
                        width={50}
                        height={50}
                        alt={card.title}
                        title={card.title}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                    /> 
                </NavLink>
            </div>
        </div>
        <div className="widget-products__info">
            <div className="widget-products__name">
                <NavLink to={`${app.lang}/products/${card.slug}`}>{card.title}</NavLink>
            </div>
            <div className="widget-products__prices">
                {card.price == 0 ? `${app.localizationsItems?.not_specified}` : <>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</>}
            </div>
        </div>
    </div>
  )
})

export default LatestItem