import React from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'

const Login = () => {
  return (
    <div>
        <Breadcrumb/>
        <div className="block">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 d-flex flex-column">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Авторизация</h3>
                                    <form>
                                        <div className="form-group">
                                            <label>Адрес электронной почты</label>
                                            <input type="email" className="form-control" placeholder="Введите адрес электронной почты"/>
                                        </div>
                                        <div className="form-group">
                                            <label>Пароль</label>
                                            <input type="password" className="form-control" placeholder="Введите пароль"/>
                                            <small className="form-text text-muted">
                                                <a href="">Не помните пароль?</a>
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="login-remember"/>
                                                        <span className="input-check__box"></span>
                                                        <svg className="input-check__icon" width="9px" height="7px">
                                                            <use href="images/sprite.svg#check-9x7"></use>
                                                        </svg>
                                                    </span>
                                                </span>
                                                <label className="form-check-label" >Запомнить меня</label>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-4">Войти</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Login