import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import CatalogServices from '../../services/general/catalogServices';
import useFetchItems from '../../hooks/useFetchItems';
import CategoriesItem from './CategoriesItem';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import CategoriesItem2 from './CategoriesItem2';

const FilterItem2 = observer(({slug}) => {
    const {app} = useContext(Context);
    const [open, setOpen] = useState(true);

    const {
        items,
    } = useFetchItems(CatalogServices.categories.index)

  return (
    <div className="widget-filters__item">
        <div className={open ? "filter filter--opened" : "filter"}>
            <button type="button" className="filter__title" onClick={() => setOpen(!open)}>
                {app.localizationsItems?.categories}
                <div className="filter__arrow">
                    <i className='fa fa-chevron-down'></i>
                </div>
            </button>
            <div className="filter__body" >
                <div className="filter__container">
                    <div className="filter-categories-alt">
                        <ul className="filter-categories-alt__list">
                            {items?.items?.map((it, index) =>
                                <CategoriesItem2 it={it} key={index} slug={slug}/>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default FilterItem2