import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PostCard from '../simple/PostCard';
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from '../../services/general/articleServices';

const Blog = ({elem}) => {
    const settings = {
        margin: 30,
        nav: true,
        dots: false,
        loop: true,
        navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
        }
    };

    const {
        items,
    } = useFetchItems(ArticleServices.index)

  return (
    <div className="block block-posts" data-layout="grid-3" data-mobile-columns="1">
        <div className="container">
            <div className="block-header">
                <h3 className="block-header__title">{elem.title}</h3>
                <div className="block-header__divider"></div>
                <div className="block-header__arrows-list">
                </div>
            </div>
            <div className="block-posts__slider">
                {items?.items &&
                    <OwlCarousel className="owl-carousel" {...settings}>
                        {items.items.map((card, index) =>
                            <PostCard card={card} key={index}/>
                        )}
                    </OwlCarousel>
                }
            </div>
        </div>
    </div>
  )
}

export default Blog