import React, { useContext } from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'
import CheckoutContainer from '../../components/complex/CheckoutContainer'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Checkout = observer(() => {
    const {app} = useContext(Context);

    const item ={
        title: app.localizationsItems?.сheckout_page
    }
  return (
    <div>
        <Breadcrumb item={item}/>
        <CheckoutContainer/>
    </div>
  )
})

export default Checkout