import React from 'react'
import { useState } from 'react';
import CustomCollapse from '../simple/CustomCollapse';

const FaqComponent = ({item}) => {
    const [currentItem, setCurrentItem] = useState(item?.elements[0]);

  return (
    <div className="block faq-area">
        <div className="container">
            <h2>{item.title}</h2>
            <div className='row'>
                <div className="col-lg-12">
                    <div className="faq-text-area">
                            <div className="accordion" id="accordionExample">
                                {item.elements?.map((elem, index) => 
                                    <CustomCollapse elem={elem} key={index} currentItem={currentItem} setCurrentItem={setCurrentItem}/>
                                )}
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FaqComponent