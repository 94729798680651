import React, { useContext, useState } from 'react'
import LatestBlogs from './LatestBlogs'
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const ArticleDetailSidebar = observer(({setFilterData}) => {
    const [term, setTerm] = useState('');
    const navigate = useNavigate();
    const {app} = useContext(Context);

    const searchOn = () => {
        navigate(`/${app.lang}/articles?term=${term}`);
    }

  return (
    <div className="col-12 col-lg-4">
        <div className="block block-sidebar block-sidebar--position--end">
            <div className="block-sidebar__item">
                <div className="widget-search">
                    <div className="widget-search__body">
                        <input className="widget-search__input" placeholder="Поиск в блоге..." type="text"
                            value={term}
                            onChange={e => setTerm(e.target.value)}
                        />
                        <button className="widget-search__button" onClick={() => searchOn()}>
                            <i className='fa fa-search'></i>
                        </button>
                    </div>
                </div>
            </div>
            <LatestBlogs/>
        </div>
    </div>
  )
})

export default ArticleDetailSidebar