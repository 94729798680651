import React, { useContext } from 'react'
import TopBar from './TopBar'
import { NavLink } from 'react-router-dom'
import Menu from './Menu'
import DropDownSearch from '../simple/DropDownSearch'
import DropdownCart from '../simple/DropdownCart'
import DropdownUser from '../simple/DropdownUser'
import { useState } from 'react'
import { useEffect } from 'react'
import СonsultationModal from '../modals/СonsultationModal'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import MobileMenu from './MobileMenu'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'

const Header = observer(() => {
    const {app} = useContext(Context);
    const [open, setOpen] = useState(false);

    const [logo, setLogo] = useState();
    const [phone, setPhone] = useState();
    const [whiteLogo, setWhiteLogo] = useState();
    const [searchEnabled, setSearchnEnabled] = useState(false);

    const [checkoutModal, setCheckoutModal] = useState(false);

    useEffect(() => {
        if(app.settings){
            setLogo(app.settings.find(it => it.type === "logo"))
            setWhiteLogo(app.settings.find(it => it.type === "white-logo"))
            setPhone(app.settings.find(it => it.type === "phone")?.value);
            setSearchnEnabled(app.settings.find(it => it.type === "search-enabled")?.value);
        }
    }, [app.settings])

    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    const [sticky, setStiky] = useState();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 120){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
    <>
        <header className="site__header d-lg-block">
            <div className="site-header">
                <div className="site-header__topbar topbar">
                    <TopBar/>
                </div>
                <div className="site-header__middle container">
                    {logo && <div className="site-header__logo">
                        <NavLink to={'/'}>
                            <img src={logo.value} alt={logo.name} title={logo.name} width='180' height='100'/>
                        </NavLink>
                    </div>}
                    <div className='slogan'>{app.localizationsItems?.tagline}</div>
                    <div className="site-header__phone">
                        <button className='btn btn-accent' onClick={() => setCheckoutModal(true)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2475 14.11L14.9275 12.43C15.1538 12.2066 15.44 12.0536 15.7516 11.9897C16.0631 11.9258 16.3865 11.9537 16.6825 12.07L18.73 12.8875C19.0291 13.0089 19.2856 13.2162 19.4671 13.4831C19.6486 13.75 19.747 14.0647 19.75 14.3875V18.1375C19.7482 18.3571 19.7021 18.5741 19.6143 18.7753C19.5265 18.9766 19.3989 19.158 19.2391 19.3087C19.0793 19.4594 18.8907 19.5761 18.6847 19.652C18.4786 19.7278 18.2593 19.7612 18.04 19.75C3.69248 18.8575 0.797478 6.70753 0.249978 2.05753C0.224563 1.82918 0.247784 1.59804 0.318114 1.37931C0.388445 1.16058 0.50429 0.959225 0.658029 0.788483C0.811768 0.61774 0.999916 0.481484 1.2101 0.388679C1.42028 0.295873 1.64772 0.248621 1.87748 0.250031H5.49998C5.82324 0.250987 6.13881 0.348631 6.40612 0.530403C6.67344 0.712174 6.88025 0.96976 6.99998 1.27003L7.81748 3.31753C7.93767 3.61233 7.96833 3.93601 7.90564 4.24814C7.84295 4.56027 7.68968 4.847 7.46498 5.07253L5.78498 6.75253C5.78498 6.75253 6.75248 13.3 13.2475 14.11Z" fill="white"/>
                            </svg>
                            {app.localizationsItems?.consultation}
                        </button>
                    </div>
                </div>
                <div className="site-header__nav-panel">
                    <div className={sticky ? "nav-panel nav-panel--sticky" : "nav-panel"} data-sticky-mode="pullToShow">
                        <div className="nav-panel__container container">
                            <div className="nav-panel__row">
                                <Menu items={items}/>
                                <button className="mobile-header__menu-button" onClick={() => setOpen(true)}>
                                    <i className='fa fa-bars'></i>
                                </button>
                                {whiteLogo && <div className="site-mob__logo">
                                    <NavLink to={'/'}>
                                        <img src={whiteLogo.value} alt={whiteLogo.name} title={whiteLogo.name} width='140' height='40'/>
                                    </NavLink>
                                </div>}
                                <div className="nav-panel__indicators">
                                    {searchEnabled && <DropDownSearch/>}
                                    {app.userCabinet && <div className="indicator">
                                        <NavLink to={'USER_PAGE_ROUTE'} className="indicator__button">
                                            <span className="indicator__area">
                                                <i className='fa fa-heart'></i>
                                                <span className="indicator__value">0</span>
                                            </span>
                                        </NavLink>
                                    </div>}
                                    <DropdownCart/>
                                    {app.userCabinet && <DropdownUser/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MobileMenu open={open} setOpen={setOpen} items={items}/>
        </header>
        {checkoutModal && <СonsultationModal show={checkoutModal} setShow={setCheckoutModal}/>}
    </>
  )
})

export default Header