import http from "./http-common"
import {getData} from "../handlers";

export default class ArticleServices {
    static index = (queryString) => {
        return getData(http, `/app/articles${queryString ? `?${queryString}` : ""}`);
    }

    static latestArticles = () => {
        return getData(http, `/app/articles`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/articles/${slug}${queryString ? `${queryString}` : ""}`);
    }

    static popular = (queryString) => {
        return getData(http, `/app/articles/popular${queryString ? `&${queryString}` : ""}`);
    }

    static categories = (queryString) => {
        return getData(http, `/app/articles/categories${queryString ? `${queryString}` : ""}`);
    }
}