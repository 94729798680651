import React, {useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import ProductSidebar2 from './ProductSidebar2'
import CatalogProducts from './CatalogProducts'
import CatalogServices from '../../services/general/catalogServices'
import { Helmet } from 'react-helmet'

const ProductsContainer2 = observer(() => {
    const [slug, setSlug] = useState();

    const [open, setOpen] = useState();
    const [categoryName, setCategoryName] = useState()

    const location = useLocation();

    const fetchCategory = async (categoryName) => {
        const res = await CatalogServices.categories.viewTitle(categoryName);

        if(res.statusCode === 200){
            setCategoryName(res.content)
        }
    }

    useEffect(() => {
        let pathArray = location.pathname.split('/');
        setSlug(pathArray[pathArray.length-1])
        fetchCategory(pathArray[pathArray.length-1])
    }, [location.pathname])

  return (
    <div className="container">
        <Helmet>
            <title>{categoryName?.title}</title>
        </Helmet>
        <div className="shop-layout shop-layout--sidebar--start">
            <ProductSidebar2 open={open} setOpen={setOpen} slug={slug}/>
            <CatalogProducts slug={slug} />
        </div>
        <div dangerouslySetInnerHTML={{__html: categoryName?.description}}></div>
    </div>
  )
})

export default ProductsContainer2