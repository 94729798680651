import React from 'react'
import { NavLink } from 'react-router-dom'

const Menu = ({items}) => {

  return (
    <div className="nav-panel__nav-links nav-links">
        <ul className="nav-links__list">
            {items?.map((elem, index) =>
                <li className="nav-links__item  nav-links__item--has-submenu" key={index}>
                    <NavLink className="nav-links__item-link" to={elem.value} target={elem.type}>
                        <div className="nav-links__item-body">
                            {elem.title}
                            {elem.items && <i className='fa fa-chevron-down'></i>}
                        </div>
                    </NavLink>
                    {elem.items &&
                        <div className="nav-links__submenu nav-links__submenu--type--menu">
                            <div className="menu menu--layout--classNameic ">
                                <div className="menu__submenus-container"></div>
                                <ul className="menu__list">
                                    {elem.items.map((it, index) =>
                                        <li className="menu__item" key={index}>
                                            <div className="menu__item-submenu-offset"></div>
                                            <NavLink className="menu__item-link" to={it.value} target={it.type}>
                                                {it.title}
                                                {it.items && <i className='fa fa-chevron-right'></i>}
                                            </NavLink>
                                            {it.items &&
                                                <div className="menu__submenu">
                                                    <div className="menu menu--layout--classNameic ">
                                                        <div className="menu__submenus-container"></div>
                                                        <ul className="menu__list">
                                                            {it.items.map((el, index) =>
                                                                <li className="menu__item" key={index}>
                                                                    <div className="menu__item-submenu-offset"></div>
                                                                    <NavLink className="menu__item-link" to={el.value} target={el.type}>
                                                                        {el.title}
                                                                    </NavLink>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    }
                </li>
            )}
        </ul>
    </div>
  )
}

export default Menu