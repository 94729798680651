import React, { useContext } from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'
import BasketContainer from '../../components/complex/BasketContainer'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Basket = observer(() => {
    const {app} = useContext(Context);

    const item ={
        title: app.localizationsItems?.basket
    }
  return (
    <div>
        <Breadcrumb item={item}/>
        <BasketContainer/>
    </div>
  )
})

export default Basket