import React, { useContext } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const BasketTr = observer(({product, setLoading}) => {
    const [count, setCount] = useState(product.count);

    const [total, setTotal] = useState();

    const {app,user} = useContext(Context);

    useEffect(() => {
        if(product){
            setTotal(Number(product.price) * Number(count))
        }
    }, [product, count])

    const changeCount = async (e) => {
        const data = {
            uid: user.uid,
            product_id: product.id,
            count: e.target.value
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            setCount(e.target.value)
            user.setBasketChange(Math.random().toString(16))
        }
    }

    const plusCount = async () => {
        const data = {
            uid: user.uid,
            product_id: product.id,
            count: count+1
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            setCount(count+1)
            user.setBasketChange(Math.random().toString(16))
        }
    }

    const minusCount = async () => {
        if(count > 1){
            const data = {
                uid: user.uid,
                product_id: product.id,
                count: count-1
            }
    
            const res = await UserServices.cart.update(data);
    
            if(res.statusCode === 200){
                setCount(count-1)
                user.setBasketChange(Math.random().toString(16))
            }
        }
    }

    const deleteItem = async () => {
        setLoading(true);
        const data = {
            uid: user.uid,
            product_id: product.id
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
        }
        setLoading(false)
    }

  return (
    <tr className="cart-table__row">
        <td className="cart-table__column cart-table__column--image">
            <div className="product-image">
                <NavLink to={`/${app.lang}/products/${product.slug}`} className="product-image__body">
                    <img className="product-image__img" src={product.image ? product.image : img} 
                        alt={product.title}
                        title={product.title}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                /> 
                </NavLink>
            </div>
        </td>
        <td className="cart-table__column cart-table__column--product">
            <NavLink to={`/${app.lang}/products/${product.slug}`} className="cart-table__product-name">{product.title}</NavLink>
        </td>
        <td className="cart-table__column cart-table__column--price" data-title={app.localizationsItems?.price}>
            {product.price == 0 ? `${app.localizationsItems?.not_specified}` : <>{Utils.numberWithSpaces(product.price)} {app.localizationsItems?.currency}</>}
        </td>
        <td className="cart-table__column cart-table__column--quantity" data-title={app.localizationsItems?.quantity}>
            <div className="input-number">
                <input className="form-control input-number__input" type="number" value={count} onChange={e => changeCount(e)}/>
                <div className="input-number__add" onClick={plusCount}></div>
                <div className="input-number__sub" onClick={minusCount}></div>
            </div>
        </td>
        <td className="cart-table__column cart-table__column--total" data-title={app.localizationsItems?.total}>{Utils.numberWithSpaces(total)} {app.localizationsItems?.currency}</td>
        <td className="cart-table__column cart-table__column--remove">
            <button type="button" className="btn btn-light btn-sm btn-svg-icon" onClick={deleteItem}>
                <i className='fa fa-trash'></i>
            </button>
        </td>
    </tr>
  )
})

export default BasketTr