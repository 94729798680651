import React, { useState } from 'react'

const MobMenuSubItem = ({el, changeNavigate}) => {
    const [open, setOpen] = useState(false);

  return (
    <li className={open ? "mobile-links__item mobile-links__item--open" : "mobile-links__item"}>
        <div className="mobile-links__item-title">
            <div onClick={() => changeNavigate(el.value)} target={el.type} className="mobile-links__item-link">{el.title}</div>
            {el.items && <button className="mobile-links__item-toggle" type="button" onClick={() => setOpen(!open)}>
                <i className='fa fa-chevron-down'></i>
            </button>}
        </div>
        {el.items && <div className="mobile-links__item-sub-links">
            <ul className="mobile-links mobile-links--level--2">
                {el.items?.map((it, index) =>
                    <li className="mobile-links__item" key={index}>
                        <div className="mobile-links__item-title">
                            <div onClick={() => changeNavigate(el.value)} target={it.type} className="mobile-links__item-link">{it.title}</div>
                        </div>
                    </li>
                )}
            </ul>
        </div>}
    </li>
  )
}

export default MobMenuSubItem