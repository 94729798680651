import React from 'react'

const Addresses = () => {
  return (
    <div className="addresses-list">
      <a href="" className="addresses-list__item addresses-list__item--new">
          <div className="addresses-list__plus"></div>
          <div className="btn btn-secondary btn-sm">Добавить</div>
      </a>
      <div className="addresses-list__divider"></div>
      <div className="addresses-list__item card address-card">
          <div className="address-card__badge">По умолчанию</div>
          <div className="address-card__body">
              <div className="address-card__name">Хелен Гарсия</div>
              <div className="address-card__row">
                  Рандом Федерация<br/>
                  115302, Москва<br/>
                  ул. Варшавская, 15-2-178
              </div>
              <div className="address-card__row">
                  <div className="address-card__row-title">Номер телефона</div>
                  <div className="address-card__row-content">38 972 588-42-36</div>
              </div>
              <div className="address-card__row">
                  <div className="address-card__row-title">Адрес электронной почты</div>
                  <div className="address-card__row-content">stroyka@example.com</div>
              </div>
              <div className="address-card__footer">
                  <a href="account-edit-address.html">Редактировать</a>&nbsp;&nbsp;
                  <a href="">Удалить</a>
              </div>
          </div>
      </div>
      <div className="addresses-list__divider"></div>
      <div className="addresses-list__item card address-card">
          <div className="address-card__body">
              <div className="address-card__name">Юпитер Сатурнов</div>
              <div className="address-card__row">
                  РандомЛэнд<br/>
                  4b4f53, МарсГрад<br/>
                  Солнечная орбита, 43.3241-85.239
              </div>
              <div className="address-card__row">
                  <div className="address-card__row-title">Номер телефона</div>
                  <div className="address-card__row-content">ZX 971 972-57-26</div>
              </div>
              <div className="address-card__row">
                  <div className="address-card__row-title">Адрес электронной почты</div>
                  <div className="address-card__row-content">stroyka@example.com</div>
              </div>
              <div className="address-card__footer">
                  <a href="account-edit-address.html">Редактировать</a>&nbsp;&nbsp;
                  <a href="">Удалить</a>
              </div>
          </div>
      </div>
      <div className="addresses-list__divider"></div>
  </div>
  )
}

export default Addresses