import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const PostItem = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="posts-list__item">
        <div className="post-card post-card--layout--grid post-card--size--nl">
            <div className="post-card__image">
                <NavLink to={`/${app.lang}/articles/${card.slug}`}>
                    <img src={card.image ? card.image : img} 
                        alt={card.title}
                        title={card.title}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    /> 
                </NavLink>
            </div>
            <div className="post-card__info">
                <div className="post-card__name">
                    <NavLink to={`/${app.lang}/articles/${card.slug}`}>{card.title}</NavLink>
                </div>
                <div className="post-card__date">{card.date}</div>
                <div className="post-card__content" dangerouslySetInnerHTML={{ __html: card.description }}></div>
            </div>
        </div>
    </div>
  )
})

export default PostItem