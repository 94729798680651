import React from 'react'

const Dashboard = () => {
  return (
    <div className="dashboard">
        <div className="dashboard__profile card profile-card">
            <div className="card-body profile-card__body">
                <div className="profile-card__avatar">
                    <img src="images/avatars/avatar-3.jpg" alt=""/>
                </div>
                <div className="profile-card__name">Хелен Гарсия</div>
                <div className="profile-card__email">stroyka@example.com</div>
                <div className="profile-card__edit">
                    <a href="account-profile.html" className="btn btn-secondary btn-sm">Редактировать профиль</a>
                </div>
            </div>
        </div>
        <div className="dashboard__address card address-card address-card--featured">
            <div className="address-card__badge">Адрес по умолчанию</div>
            <div className="address-card__body">
                <div className="address-card__name">Хелен Гарсия</div>
                <div className="address-card__row">
                    Рандом Федерация<br/>
                    115302, Москва<br/>
                    ул. Варшавская, 15-2-178
                </div>
                <div className="address-card__row">
                    <div className="address-card__row-title">Номер телефона</div>
                    <div className="address-card__row-content">38 972 588-42-36</div>
                </div>
                <div className="address-card__row">
                    <div className="address-card__row-title">Адрес электронной почты</div>
                    <div className="address-card__row-content">stroyka@example.com</div>
                </div>
                <div className="address-card__footer">
                    <a href="account-edit-address.html">Редактировать адрес</a>
                </div>
            </div>
        </div>
        <div className="dashboard__orders card">
            <div className="card-header">
                <h5>Последние заказы</h5>
            </div>
            <div className="card-divider"></div>
            <div className="card-table">
                <div className="table-responsive-sm">
                    <table>
                        <thead>
                            <tr>
                                <th>Заказ</th>
                                <th>Дата</th>
                                <th>Статус</th>
                                <th>Итог</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><a href="">#8132</a></td>
                                <td>2 апреля, 2019</td>
                                <td>В ожидании</td>
                                <td>$2,719.00 за 5 шт.</td>
                            </tr>
                            <tr>
                                <td><a href="">#7592</a></td>
                                <td>28 Марта, 2019</td>
                                <td>В ожидании</td>
                                <td>$374.00 за 3 шт.</td>
                            </tr>
                            <tr>
                                <td><a href="">#7192</a></td>
                                <td>15 Марта, 2019</td>
                                <td>Доставлено</td>
                                <td>$791.00 за 4 шт.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard