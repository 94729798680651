import React, { useContext } from 'react'
import { useForm } from 'react-hook-form';
import useFetchItems from '../../hooks/useFetchItems';
import Utils from '../../services/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const CheckoutContainer = observer(() => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();

    const navigate = useNavigate();

    const [activePayment, setActivePayment] = useState();
    const [activeDelivery, setActiveDelivery] = useState();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {
        items:payments,
    } = useFetchItems(UserServices.cart.order.payments)

    const {
        items:deliveries,
    } = useFetchItems(UserServices.cart.order.deliveries)

    const CustomSubmit = async (data) => {
        setLoading(true)
        const {address, ...rest} = data

        const newData ={
            ...rest,
            json_address: address,
            full_address: address,
            uid: user.uid
        }

        const res = await UserServices.cart.order.order(newData);

        if(res.statusCode === 200){
            user.removeUid();
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}/order-success`)
        }
        setLoading(false)
    }

  return (
    <div className={loading ? "checkout block isLoading" : "checkout block"}>
        <div className="container">
            <form className="row" onSubmit={handleSubmit(CustomSubmit)}>
                <div className="col-12 col-lg-6 col-xl-7">
                    <div className="card mb-lg-0">
                        <div className="card-body">
                            <h3 className="card-title">{app.localizationsItems?.account_details}</h3>
                            <div className="form-group">
                                <label>{app.localizationsItems?.name} *</label>
                                <input {...register('name', {required: true})} type="text" required className="form-control" placeholder={app.localizationsItems?.name}/>
                            </div>
                            <div className="form-group">
                                <label >{app.localizationsItems?.phone} *</label>
                                <input {...register('phone', {required: true})} required type="text" className="form-control" placeholder={app.localizationsItems?.phone}/>
                            </div>
                            <div className="form-group">
                                <label>{app.localizationsItems?.email} *</label>
                                <input {...register('email', {required: true})} required type="email" className="form-control" placeholder={app.localizationsItems?.email}/>
                            </div>
                            <div className="form-group">
                                <label>{app.localizationsItems?.address}</label>
                                <input {...register('address')} type="text" className="form-control" placeholder={app.localizationsItems?.address}/>
                            </div>
                            <div className="form-group">
                                <label>{app.localizationsItems?.company_name}<span className="text-muted">({app.localizationsItems?.optional})</span></label>
                                <input {...register('company')} type="text" className="form-control" placeholder={app.localizationsItems?.company_name}/>
                            </div>
                            <div className="form-group">
                                <label >{app.localizationsItems?.order_comments}<span className="text-muted">({app.localizationsItems?.optional})</span></label>
                                <textarea {...register('description')} className="form-control" rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                    <div className="card mb-0">
                        <div className="card-body">
                            <h3 className="card-title">{app.localizationsItems?.your_order}</h3>
                            <table className="checkout__totals">
                                <thead className="checkout__totals-header">
                                    <tr>
                                        <th>{app.localizationsItems?.product}</th>
                                        <th>{app.localizationsItems?.total}</th>
                                    </tr>
                                </thead>
                                <tbody className="checkout__totals-products">
                                    {user.cartProducts?.map((product, index) =>
                                        <tr key={index}>
                                            <td>{product.title} × {product.count}</td>
                                            <td>{Utils.numberWithSpaces(Number(product.price) * Number(product.count))} {app.localizationsItems?.currency}</td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot className="checkout__totals-footer">
                                    <tr>
                                        <th>{app.localizationsItems?.total}</th>
                                        <td>{Utils.numberWithSpaces(user.totalSum)} {app.localizationsItems?.currency}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            {deliveries && <div className="payment-methods">
                                <p>{app.localizationsItems.delivery_method} *</p>
                                <ul className={errors.delivery_id ? "payment-methods__list error" : "payment-methods__list"}>
                                    {deliveries?.map((delivery, index) =>
                                        <li className={activeDelivery === delivery ? "payment-methods__item payment-methods__item--active" : "payment-methods__item"} key={index}
                                            onClick={() => setActiveDelivery(delivery)}
                                        >
                                            <label className="payment-methods__item-header">
                                                <span className="payment-methods__item-radio input-radio">
                                                    <span className="input-radio__body">
                                                        <input className="input-radio__input" name="checkout_payment_method" value={delivery.id} type="radio" {...register('delivery_id', {required: true})}/>
                                                        <span className="input-radio__circle"></span>
                                                    </span>
                                                </span>
                                                <span className="payment-methods__item-title">{delivery.name}</span>
                                            </label>
                                            <div className="payment-methods__item-container">
                                                <div className="payment-methods__item-description text-muted">
                                                    {delivery.description}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>}
                            <div className="card-divider mb-4"></div>
                            {payments && <div className="payment-methods">
                                <p>{app.localizationsItems?.payment_method} *</p>
                                <ul className={errors.payment_id ? "payment-methods__list error" : "payment-methods__list"}>
                                    {payments.map((payment, index) =>
                                        <li className={activePayment === payment ? "payment-methods__item payment-methods__item--active" : "payment-methods__item"} key={index} 
                                            onClick={() => setActivePayment(payment)}
                                            >
                                            <label className="payment-methods__item-header">
                                                <span className="payment-methods__item-radio input-radio">
                                                    <span className="input-radio__body">
                                                        <input className="input-radio__input" name="checkout_payment_method" value={payment.id} type="radio" {...register('payment_id', {required: true})}/>
                                                        <span className="input-radio__circle"></span>
                                                    </span>
                                                </span>
                                                <span className="payment-methods__item-title">{payment.name}</span>
                                            </label>
                                            <div className="payment-methods__item-container">
                                                <div className="payment-methods__item-description text-muted">
                                                    {payment.description}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>}
                            <button type="submit" className="btn btn-primary btn-xl btn-block">{app.localizationsItems?.checkout}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
})

export default CheckoutContainer