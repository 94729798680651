import React from 'react'

const Orders = () => {
  return (
    <div className="card">
      <div className="card-header">
          <h5>Итория заказов</h5>
      </div>
      <div className="card-divider"></div>
      <div className="card-table">
          <div className="table-responsive-sm">
              <table>
                  <thead>
                      <tr>
                          <th>Заказ</th>
                          <th>Дата</th>
                          <th>Статус</th>
                          <th>Итог</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td><a href="account-order-details.html">#8132</a></td>
                          <td>2 Апреля, 2019</td>
                          <td>В ожидании</td>
                          <td>$2,719.00 за 5 шт.</td>
                      </tr>
                      <tr>
                          <td><a href="account-order-details.html">#7592</a></td>
                          <td>28 Марта, 2019</td>
                          <td>В ожидании</td>
                          <td>$374.00 за 3 шт.</td>
                      </tr>
                      <tr>
                          <td><a href="account-order-details.html">#7192</a></td>
                          <td>15 Марта, 2019</td>
                          <td>Доставлено</td>
                          <td>$791.00 за 4 шт.</td>
                      </tr>
                      <tr>
                          <td><a href="account-order-details.html">#6321</a></td>
                          <td>28 Февраля, 2019</td>
                          <td>Выполнено</td>
                          <td>$57.00 за 1 шт.</td>
                      </tr>
                      <tr>
                          <td><a href="account-order-details.html">#6001</a></td>
                          <td>21 Февраля, 2019</td>
                          <td>Выполнено</td>
                          <td>$252.00 за 2 шт.</td>
                      </tr>
                      <tr>
                          <td><a href="account-order-details.html">#4120</a></td>
                          <td>11 Декабря, 2018</td>
                          <td>Выполнено</td>
                          <td>$3,978.00 за 7 шт.</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
      <div className="card-divider"></div>
      <div className="card-footer">
          <ul className="pagination justify-content-center">
              <li className="page-item disabled">
                  <a className="page-link page-link--with-arrow" href="" aria-label="Previous">
                      <svg className="page-link__arrow page-link__arrow--left" aria-hidden="true" width="8px" height="13px">
                          <use href="images/sprite.svg#arrow-rounded-left-8x13"></use>
                      </svg>
                  </a>
              </li>
              <li className="page-item"><a className="page-link" href="">1</a></li>
              <li className="page-item active"><a className="page-link" href="">2 <span className="sr-only">(current)</span></a></li>
              <li className="page-item"><a className="page-link" href="">3</a></li>
              <li className="page-item">
                  <a className="page-link page-link--with-arrow" href="" aria-label="Next">
                      <svg className="page-link__arrow page-link__arrow--right" aria-hidden="true" width="8px" height="13px">
                          <use href="images/sprite.svg#arrow-rounded-right-8x13"></use>
                      </svg>
                  </a>
              </li>
          </ul>
      </div>
  </div>
  )
}

export default Orders