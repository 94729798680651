import React from 'react'
import BreadcrumbTitle from '../../components/simple/BreadcrumbTitle'
import ProfileContainer from './ProfileContainer'

const Profile = () => {
  return (
    <div>
        <BreadcrumbTitle/>
        <ProfileContainer/>
    </div>
  )
}

export default Profile