import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import QuickViewModal from '../modals/QuickViewModal'
import Utils from '../../services/utils'
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const OfferItem = observer(({card}) => {
    const [show, setShow] = useState(false);
    const {app} = useContext(Context);

    const handleShow = () => setShow(true);

  return (
    <div className="block-product-columns__item">
        <div className="product-card product-card--hidden-actions product-card--layout--horizontal">
            <button className="product-card__quickview" type="button" onClick={handleShow}>
                <i className='fa fa-expand'></i>
            </button>
            <div className="product-card__image product-image">
                <NavLink to={`/${app.lang}/products/${card.slug}`} className="product-image__body">
                    <img className="product-image__img" src={card.preview_image ? card.preview_image : img} 
                        width={88}
                        height={88}
                        alt={card.title}
                        title={card.title}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    /> 
                </NavLink>
            </div>
            <div className="product-card__info">
                <div className="product-card__name">
                    <NavLink to={`/${app.lang}/products/${card.slug}`}>{card.title}</NavLink>
                </div>
                <div className="product-card__prices">
                    {card.old_price ?
                        <>
                            <span className="product-card__new-price">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency} </span>
                            <span className="product-card__old-price">{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>
                        </>
                        :
                        <>{card.price == 0 ? `${app.localizationsItems?.not_specified}` : <>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</>}</>
                    }
                </div>
            </div>
        </div>
        {show && <QuickViewModal show={show} setShow={setShow} slug={card?.slug}/>}
    </div>
  )
})

export default OfferItem