import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const CheckoutModal = observer(({show, setShow, productName}) => {
    const [slider, setSlider] = useState();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const {app} = useContext(Context);

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const handleClose = () => setShow(false);

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const CustomSubmit = async (data) => {
        let query = new URLSearchParams(window.location.search);

        const {name, phone} = data;
        
        let newData = {
            fields: {
                Имя: name, 
                Номер: phone,
                Товар: productName
            }
        }

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            getCookie('utm_source') && newData.append('utm_source', getCookie('utm_source'));
            getCookie('utm_medium') && newData.append('utm_medium', getCookie('utm_medium'));
            getCookie('utm_campaign') && newData.append('utm_campaign', getCookie('utm_campaign'));
            getCookie('utm_content') && newData.append('utm_content', getCookie('utm_content'));
            getCookie('utm_term') && newData.append('utm_term', getCookie('utm_term'));
        }

        setLoading(true);

        const res = await AppServices.requestTemplate(newData);

        if (res.statusCode === 200) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                handleClose();
            }, 3000);
        }
        setLoading(false);
    };

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <form className='checkout-form' onSubmit={handleSubmit(CustomSubmit)}>
                <div className="text-center">
                    <h2>{app.localizationsItems?.form_title}</h2>
                    <p>{app.localizationsItems?.form_content}</p>
                </div>
                <div className="form-group">
                    <label>{app.localizationsItems?.name}</label>
                    <input {...register('name')} required type="text" className="form-control" placeholder={app.localizationsItems?.name_placeholder}/>
                </div>
                <div className="form-group">
                    <label>{app.localizationsItems?.phone}</label>
                    <input  {...register('phone')} required type="text" className="form-control" placeholder={app.localizationsItems?.phone_placeholder}/>
                </div>
                <input  {...register('product')} hidden type="text" className="form-control"/>
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <div className="form-group mb-0">
                            <button className="btn btn-primary" type='submit'>{app.localizationsItems?.form_button}</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className={visible ? 'form-success form-visible' : 'form-success'}>
                <i className='fa fa-check-circle'></i>
                <h2>{app.localizationsItems?.form_success_title}</h2>
                <p>{app.localizationsItems?.form_success_text}</p>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default CheckoutModal