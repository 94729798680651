import React from 'react'
import img from "../../images/placeholder.png"

const ReviewCard = ({card}) => {
  return (
    <div className='rewiev-card'>
        <div className='review-block'>
            <img src={card.image}
                alt={card.title}
                title={card.title}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className='review-container'>
                <div dangerouslySetInnerHTML={{ __html: card.description }}></div>
                <div className='review-title'>
                    {card.title}
                </div>
            </div>
        </div>
        {card.image && card.subtitle &&
        <div className='review-wrapper'>
            <img src={card.image}
                alt={card.title}
                title={card.title}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <p>{card.subtitle}</p>
        </div>}
    </div>
  )
}

export default ReviewCard