import React, { useState } from 'react'
import MobMenuSubItem from './MobMenuSubItem';

const MobMenuItem = ({item, changeNavigate}) => {
    const [open, setOpen] = useState(false);

  return (
    <li className={open ? "mobile-links__item mobile-links__item--open" : "mobile-links__item"}>
        <div className="mobile-links__item-title">
            <div onClick={() => changeNavigate(item.value)} target={item.type} className="mobile-links__item-link">{item.title}</div>
            {item.items && <button className="mobile-links__item-toggle" type="button" onClick={() => setOpen(!open)}>
                <i className='fa fa-chevron-down'></i>
            </button>}
        </div>
        {item.items && 
            <div className="mobile-links__item-sub-links">
                <ul className="mobile-links mobile-links--level--1">
                    {item.items?.map((el, index) =>
                        <MobMenuSubItem key={index} el={el} changeNavigate={changeNavigate}/>
                    )}
                </ul>
            </div>
        }
    </li>
  )
}

export default MobMenuItem