import React from 'react'

const Password = () => {
  return (
    <div className="card">
      <div className="card-header">
          <h5>Change Password</h5>
      </div>
      <div className="card-divider"></div>
      <div className="card-body">
          <div className="row no-gutters">
              <div className="col-12 col-lg-7 col-xl-6">
                  <div className="form-group">
                      <label >Current Password</label>
                      <input type="password" className="form-control" id="password-current" placeholder="Current Password"/>
                  </div>
                  <div className="form-group">
                      <label >New Password</label>
                      <input type="password" className="form-control" id="password-new" placeholder="New Password"/>
                  </div>
                  <div className="form-group">
                      <label >Reenter New Password</label>
                      <input type="password" className="form-control" id="password-confirm" placeholder="Reenter New Password"/>
                  </div>
                  <div className="form-group mt-5 mb-0">
                      <button className="btn btn-primary">Change</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default Password