import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import FilterItem2 from '../simple/FilterItem2'

const ProductSidebar2 = observer(({ open, setOpen, slug}) => {
    const {app} = useContext(Context);

  return (
    <div className="shop-layout__sidebar">
        <div className={open ? "block block-sidebar block-sidebar--offcanvas--mobile block-sidebar--open" : "block block-sidebar block-sidebar--offcanvas--mobile"}>
            <div className="block-sidebar__backdrop"></div>
            <div className="block-sidebar__body">
                <div className="block-sidebar__header">
                    <div className="block-sidebar__title">{app.localizationsItems?.filters}</div>
                    <button className="block-sidebar__close" type="button" onClick={() => setOpen(false)}>
                        <i className='fa fa-times'></i>
                    </button>
                </div>
                <div className="block-sidebar__item">
                    <div className="widget-filters widget widget-filters--offcanvas--mobile">
                        <h4 className="widget-filters__title widget__title">{app.localizationsItems?.filters}</h4>
                        <div className="widget-filters__list">
                            <FilterItem2 slug={slug}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default ProductSidebar2