import React, { useEffect, useState } from 'react'
import LatestBlogs from './LatestBlogs'
import { useLocation, useNavigate } from 'react-router-dom';

const ArticleSidebar = ({setFilterData}) => {
    const [term, setTerm] = useState('');
    const navigate = useNavigate();
    let query = new URLSearchParams(useLocation().search);

    const searchOn = () => {
        setFilterData(`?term=${term}`)
        navigate({
            search: `?term=${term}`
        });
    }

    useEffect(() => {
        if(query.get('term')){
            setTerm(query.get('term'))
        }
    }, [])

  return (
    <div className="col-12 col-lg-4">
        <div className="block block-sidebar block-sidebar--position--end">
            <div className="block-sidebar__item">
                <div className="widget-search">
                    <div className="widget-search__body">
                        <input className="widget-search__input" placeholder="Поиск в блоге..." type="text"
                            value={term}
                            onChange={e => setTerm(e.target.value)}
                        />
                        <button className="widget-search__button" onClick={() => searchOn()}>
                            <i className='fa fa-search'></i>
                        </button>
                    </div>
                </div>
            </div>
            <LatestBlogs/>
        </div>
    </div>
  )
}

export default ArticleSidebar