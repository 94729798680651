import React, { useContext, useEffect } from 'react'
import ProductSidebar from './ProductSidebar'
import Pagination from './Pagination'
import ProductCard from '../simple/ProductCard'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const ProductsContainer = observer(() => {
    const {app} = useContext(Context);
    const {slug} = useParams();
    const [active, setActive] = useState(1);
    const [layout, setLayout] = useState('grid-3-sidebar');
    const [feature, setFeature] = useState(false);
    const [open, setOpen] = useState(false);

    let query = new URLSearchParams(useLocation().search);
    const [pageCount, setPageCount] = useState();
    const [params, setParams] = useState(query);
    const [loading, setLoading] = useState();
    const [items, setItems] = useState();

    const navigate = useNavigate();

    const fetchProducts = async () => {
        setLoading(true);
        let str2 = query.toString();
        let str3 = slug ? `categories[]=${slug}&` : ``;

        const res = await CatalogServices.products.index(`${str3}${str2}`);

        if(res.statusCode === 200){
            setItems(res.content);
            setPageCount(res.content?.total_pages);
        }
        setLoading(false);
    }
    const changePositions = (feature, count, layout) => {
        setActive(count);
        setLayout(layout);
        setFeature(feature)
    }

    useEffect(() => {
        if(!query.get('page')){
            params.set('page', 1);
        }
        fetchProducts()
    }, [params, query.get('categories[]')])

    const handlePageClick = (data) => {
        query.set('page', data.selected + 1);
        setParams(query);
        
        navigate({
            search: query.toString()
        });
    };

    const setFilterData = (filterString) => {
        let newParams = new URLSearchParams(filterString);
        if (query.get('order')) {
            newParams.set('order', query.get('order'))
        }
        setParams(newParams);
    };
  return (
    <div className="container">
        <div className="shop-layout shop-layout--sidebar--start">
            <ProductSidebar setFilterData={setFilterData} params={params} open={open} setOpen={setOpen} items={items}/>
            <div className="shop-layout__content">
                <div className="block">
                    <div className="products-view">
                        <div className="products-view__options">
                            <div className="view-options view-options--offcanvas--mobile">
                                <div className="view-options__filters-button">
                                    <button type="button" className="filters-button" onClick={() => setOpen(true)}>
                                        <span className="filters-button__title">{app.localizationsItems?.filters}</span>
                                    </button>
                                </div>
                                <div className="view-options__layout">
                                    <div className="layout-switcher">
                                        <div className="layout-switcher__list">
                                            <button onClick={() => changePositions(false, 1, "grid-3-sidebar")} title="Grid" type="button" className={active === 1 ? "layout-switcher__button layout-switcher__button--active" : "layout-switcher__button"}>
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.94827 0.114293C4.58478 -0.0380976 3.17574 -0.0380976 1.81224 0.114293C0.935063 0.212331 0.22576 0.903085 0.122317 1.78752C-0.0407725 3.18194 -0.0407724 4.59061 0.122317 5.98503C0.22576 6.86947 0.935063 7.56022 1.81224 7.65826C3.17574 7.81065 4.58478 7.81065 5.94827 7.65826C6.82545 7.56022 7.53475 6.86947 7.6382 5.98503C7.80129 4.59061 7.80129 3.18194 7.6382 1.78752C7.53475 0.903085 6.82545 0.212331 5.94827 0.114293Z" />
                                                    <path d="M5.94827 10.1143C4.58478 9.9619 3.17574 9.9619 1.81224 10.1143C0.935063 10.2123 0.22576 10.9031 0.122317 11.7875C-0.0407725 13.1819 -0.0407724 14.5906 0.122317 15.985C0.22576 16.8695 0.935063 17.5602 1.81224 17.6583C3.17574 17.8106 4.58478 17.8106 5.94827 17.6583C6.82545 17.5602 7.53475 16.8695 7.6382 15.985C7.80129 14.5906 7.80129 13.1819 7.6382 11.7875C7.53475 10.9031 6.82545 10.2123 5.94827 10.1143Z"/>
                                                    <path d="M15.9483 0.114293C14.5848 -0.0380976 13.1758 -0.0380976 11.8123 0.114293C10.9351 0.212331 10.2258 0.903085 10.1223 1.78752C9.95924 3.18194 9.95924 4.59061 10.1223 5.98503C10.2258 6.86947 10.9351 7.56022 11.8123 7.65826C13.1758 7.81065 14.5848 7.81065 15.9483 7.65826C16.8255 7.56022 17.5348 6.86947 17.6382 5.98503C17.8013 4.59061 17.8013 3.18194 17.6382 1.78752C17.5348 0.903085 16.8255 0.212331 15.9483 0.114293Z"/>
                                                    <path d="M15.9483 10.1143C14.5848 9.9619 13.1758 9.9619 11.8123 10.1143C10.9351 10.2123 10.2258 10.9031 10.1223 11.7875C9.95924 13.1819 9.95924 14.5906 10.1223 15.985C10.2258 16.8695 10.9351 17.5602 11.8123 17.6583C13.1758 17.8106 14.5848 17.8106 15.9483 17.6583C16.8255 17.5602 17.5348 16.8695 17.6382 15.985C17.8013 14.5906 17.8013 13.1819 17.6382 11.7875C17.5348 10.9031 16.8255 10.2123 15.9483 10.1143Z"/>
                                                </svg>
                                            </button>
                                            <button onClick={() => changePositions(true, 2, "grid-3-sidebar")} title="Grid With Features" type="button" className={active === 2 ? "layout-switcher__button layout-switcher__button--active" : "layout-switcher__button"}>
                                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.93793 15.8684C7.41058 11.0353 7.41058 6.16763 6.93793 1.33455C6.89542 0.899828 6.59728 0.533759 6.18522 0.399995C4.5423 -0.133331 2.75012 -0.133332 1.1072 0.399995C0.695142 0.533758 0.396999 0.899827 0.354486 1.33455C-0.118162 6.16763 -0.118162 11.0353 0.354485 15.8684C0.396998 16.3031 0.69514 16.6692 1.1072 16.8029C2.75012 17.3363 4.5423 17.3363 6.18521 16.8029C6.59728 16.6692 6.89542 16.3031 6.93793 15.8684Z" />
                                                    <path d="M16.4379 15.8684C16.9106 11.0353 16.9106 6.16763 16.4379 1.33455C16.3954 0.899828 16.0973 0.533759 15.6852 0.399995C14.0423 -0.133331 12.2501 -0.133332 10.6072 0.399995C10.1951 0.533758 9.897 0.899827 9.85449 1.33455C9.38184 6.16763 9.38184 11.0353 9.85448 15.8684C9.897 16.3031 10.1951 16.6692 10.6072 16.8029C12.2501 17.3363 14.0423 17.3363 15.6852 16.8029C16.0973 16.6692 16.3954 16.3031 16.4379 15.8684Z" />
                                                </svg>
                                            </button>
                                            <button onClick={() => changePositions(false, 3, "list")} title="List" type="button" className={active === 3 ? "layout-switcher__button layout-switcher__button--active" : "layout-switcher__button"}>
                                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.8684 0.354485C11.0353 -0.118162 6.16763 -0.118162 1.33455 0.354485C0.899828 0.396998 0.533759 0.695141 0.399995 1.1072C-0.133332 2.75012 -0.133332 4.5423 0.399995 6.18522C0.533758 6.59728 0.899827 6.89542 1.33455 6.93793C6.16763 7.41058 11.0353 7.41058 15.8684 6.93793C16.3031 6.89542 16.6692 6.59728 16.8029 6.18522C17.3363 4.5423 17.3363 2.75012 16.8029 1.1072C16.6692 0.695141 16.3031 0.396998 15.8684 0.354485Z"/>
                                                    <path d="M15.8684 9.85449C11.0353 9.38184 6.16763 9.38184 1.33455 9.85449C0.899828 9.897 0.533759 10.1951 0.399995 10.6072C-0.133332 12.2501 -0.133332 14.0423 0.399995 15.6852C0.533758 16.0973 0.899827 16.3954 1.33455 16.4379C6.16763 16.9106 11.0353 16.9106 15.8684 16.4379C16.3031 16.3954 16.6692 16.0973 16.8029 15.6852C17.3363 14.0423 17.3363 12.2501 16.8029 10.6072C16.6692 10.1951 16.3031 9.897 15.8684 9.85449Z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={loading ? "products-view__list products-list isLoading" : "products-view__list products-list"} data-layout={layout} data-with-features={feature} data-mobile-grid-columns="2">
                            <div className="products-list__body">
                                {items?.items?.map((card, index) =>
                                    <div className="products-list__item" key={index}>
                                        <ProductCard card={card}/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default ProductsContainer