import React, { useEffect, useContext, useRef } from 'react'
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import { useNavigate } from 'react-router-dom';
import DropDownProduct from './DropDownProduct';
import Utils from '../../services/utils';
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const DropdownCart = observer(() => {
    const {app, user} = useContext(Context);
    
    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    const navigate = useNavigate();

    const fetchItems = async () => {
        const res = user.uid ? await UserServices.cart.index(user.uid) : await UserServices.cart.index();

        if(res.statusCode === 200){
            user.setCartProducts(res.content?.products)
            user.setTotalSum(res.content?.total_price)

            if(res.content?.uid !== user.uid){
                res.content?.uid && user.setUid(res.content.uid)
            }
        }
    }

    useEffect(() => {
        fetchItems();
    }, [user.basketChange])

    const toBasket = (value) => {
        navigate(value);
        setIsActive(false)
    }

  return (
    <div className={isActive ? "indicator indicator--trigger--click indicator--display indicator--open" : "indicator indicator--trigger--click"} ref={dropdownRef}>
        <div className="indicator__button" onClick={() => setIsActive(!isActive)}>
            <span className="indicator__area">
                <i className='fa fa-cart-arrow-down'></i>
                <span className="indicator__value">{user.cartProducts?.length ? user?.cartProducts?.length : 0}</span>
            </span>
        </div>
        <div className="indicator__dropdown">
            <div className="dropcart dropcart--style--dropdown">
                <div className="dropcart__body">
                    <div className="dropcart__products-list">
                        {user.cartProducts?.map((product, index) =>
                            <DropDownProduct key={index} product={product}/>
                        )}
                    </div>
                    <div className="dropcart__totals">
                        <table>
                            <tbody>
                                <tr>
                                    <th>{app.localizationsItems?.total}</th>
                                    <td>{Utils.numberWithSpaces(user.totalSum)} {app.localizationsItems?.currency}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="dropcart__buttons">
                        <div className="btn btn-secondary" onClick={() => toBasket(`/${app.lang}/basket`)}>{app.localizationsItems?.cart}</div>
                        <button className="btn btn-primary" onClick={() => toBasket(`/${app.lang}/checkout`)} disabled={user?.cartProducts?.length > 0 ? false : true} >{app.localizationsItems?.checkout}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default DropdownCart