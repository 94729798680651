import React from 'react'

const Advantages = ({elem}) => {
  return (
    <div className="block block-features block-features--layout--boxed">
        <div className="container">
            <div className="block-features__list">
                {elem.elements?.map((card, index) =>
                    <div className="block-features__item" key={index}>
                        <div className="block-features__icon">
                            <img src={card.image} alt={card.title} title={card.title} height='195' width='350'/>
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">{card.title}</div>
                            <div className="block-features__subtitle">{card.subtitle}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Advantages