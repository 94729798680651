import React from 'react'

const FaqContainer = () => {
  return (
    <div className="block faq">
        <div className="container">
            <div className="faq__section">
                <div className="faq__section-title">
                    <h3>Информация о доставке</h3>
                </div>
                <div className="faq__section-body">
                    <div className="row">
                        <div className="faq__section-column col-12 col-lg-6">
                            <div className="typography">
                                <h6>Какие способы доставки доступны?</h6>
                                <p>
                                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </p>
                                <h6>Вы доставляете по всему миру?</h6>
                                <p>
                                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </p>
                            </div>
                        </div>
                        <div className="faq__section-column col-12 col-lg-6">
                            <div className="typography">
                                <h6>Какие способы доставки доступны?</h6>
                                <p>
                                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </p>
                                <h6>Вы доставляете по всему миру?</h6>
                                <p>
                                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq__section">
                <div className="faq__section-title">
                    <h3>Платежная информация</h3>
                </div>
                <div className="faq__section-body">
                    <div className="row">
                        <div className="faq__section-column col-12 col-lg-6">
                            <div className="typography">
                                <h6>Какие способы доставки доступны?</h6>
                                <p>
                                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </p>
                            </div>
                        </div>
                        <div className="faq__section-column col-12 col-lg-6">
                            <div className="typography">
                                <h6>Какие способы доставки доступны?</h6>
                                <p>
                                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq__section">
                <div className="faq__section-title">
                    <h3>Заказы и возвраты</h3>
                </div>
                <div className="faq__section-body">
                    <div className="row">
                        <div className="faq__section-column col-12 col-lg-6">
                            <div className="typography">
                                <h6>Какие способы доставки доступны?</h6>
                                <p>
                                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </p>
                            </div>
                        </div>
                        <div className="faq__section-column col-12 col-lg-6">
                            <div className="typography">
                                <h6>Какие способы доставки доступны?</h6>
                                <p>
                                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                    aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FaqContainer