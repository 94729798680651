import React, { useState } from 'react'

const CategoriesItem = ({it, setFilter, filterItems}) => {
    const [open, setOpen] = useState()

  return (
    <>
        {it.elements?.length >= 1 ?
            <li 
                className={(open && filterItems.find(el => el === it.slug)) ? 
                    "filter-categories-alt__item filter-categories-alt__item--open filter-categories__item--current" : 
                    filterItems.find(el => el === it.slug) ?
                    'filter-categories-alt__item filter-categories__item--current'
                    :
                    open ?
                    'filter-categories-alt__item filter-categories-alt__item--open'
                    :
                    'filter-categories-alt__item'
                }
                
            >
                <button className="filter-categories-alt__expander" onClick={() => setOpen(!open)}></button>
                <span onClick={() =>setFilter(it.slug)}>{it.title}</span>
                <div className="filter-categories-alt__children" >
                    <ul className="filter-categories-alt__list filter-categories-alt__list--level--2">
                        {it.elements.map((elem, index) =>
                            <CategoriesItem it={elem} key={index} setFilter={setFilter} filterItems={filterItems}/>
                        )}
                    </ul>
                </div>
            </li>
            :
            <li onClick={() =>setFilter(it.slug)}
                className={filterItems.find(el => el === it.slug) ? "filter-categories-alt__item filter-categories__item--current" : 'filter-categories-alt__item'}>
                {it.title}
            </li>
        }
    </>
  )
}

export default CategoriesItem