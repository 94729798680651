import React, { useContext } from 'react'
import QuickViewModal from '../modals/QuickViewModal'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import CheckoutModal from '../modals/CheckoutModal';
import Utils from '../../services/utils';
import ToCartModal from '../modals/ToCartModal';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const ProductCard = observer(({card}) => {
    const [show, setShow] = useState(false);
    const {app} = useContext(Context);

    const [visible, setVisible] = useState(false);

    const [checkoutModal, setCheckoutModal] = useState(false);
    const [toCartModal, setToCartModal] = useState(false);

    const handleShow = () => setShow(true);

    const addToBasket = () => {
        setToCartModal(true)
    }

  return (
    <div className="product-card product-card--hidden-actions ">
        <button className="product-card__quickview" type="button" onClick={handleShow}>
            <i className='fa fa-expand'></i>
        </button>
        <div className="product-card__image product-image">
            <NavLink to={`/${app.lang}/products/${card.slug}`} className="product-image__body">
                <img className="product-image__img" src={card.image ? card.image : img}
                    width={180}
                    height={180}
                    alt={card.title}
                    title={card.title}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                /> 
            </NavLink>
        </div>
        <div className="product-card__info">
            <div className="product-card__name">
                <NavLink to={`/${app.lang}/products/${card.slug}`}>{card.title}</NavLink>
            </div>
            <ul className="product-card__features-list">
                {card?.features?.map((it, index) =>
                    <li key={index}>{it.title}: {it.value}</li>
                )}
            </ul>
        </div>
        <div className="product-card__actions">
            <div className="product-card__availability">
                {app.localizationsItems?.availability}: {
                        card?.availability ?
                            <span className="text-success">{app.localizationsItems?.in_stock}</span>
                        :
                            <span className="text-danger">{app.localizationsItems?.not_available}</span>
                }
            </div>
            <div className="product-card__prices">
                {card.old_price ?
                    <>
                        <span className="product-card__new-price">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency} </span>
                        <span className="product-card__old-price">{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>
                    </>
                    :
                    <>{card.price == 0 ? `${app.localizationsItems?.not_specified}` : <>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</>}</>
                }
            </div>
            <div className="product-card__buttons">
                <button className={visible ? "btn btn-outline-cart product-card__addtocart" : "btn btn-primary product-card__addtocart"} type="button"
                    onClick={addToBasket}
                    disabled={!card.availability}
                >
                    {visible ? app.localizationsItems?.on_cart :app.localizationsItems?.to_cart}
                </button>
                <button className="btn btn-secondary" type="button" onClick={() => setCheckoutModal(true)} disabled={!card.availability}>
                    {app.localizationsItems?.checkout}
                </button>
            </div>
        </div>
        {show && <QuickViewModal show={show} setShow={setShow} slug={card?.slug}/>}
        {checkoutModal && <CheckoutModal show={checkoutModal} setShow={setCheckoutModal} productName={card?.title}/>}
        {toCartModal && <ToCartModal show={toCartModal} setShow={setToCartModal} slug={card?.slug}/>}
    </div>
  )
})

export default ProductCard