import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const OrderSuccess = observer(() => {
    const {app} = useContext(Context);

  return (
    <div className="block order-success">
        <div className="container">
            <div className="order-success__body">
                <div className="order-success__header">
                    <i className='fa fa-check-circle'></i>
                    <h1 className="order-success__title">{app.localizationsItems?.order_thanks}</h1>
                    <div className="order-success__subtitle">{app.localizationsItems?.order_success_content}</div>
                    <div className="order-success__actions">
                        <NavLink to={`/${app.lang}`} className="btn btn-xs btn-secondary">{app.localizationsItems?.to_main}</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default OrderSuccess