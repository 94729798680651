import React from 'react'
import WishListTr from '../simple/WishListTr'

const WishListContainer = () => {
  return (
    <div className="block">
        <div className="container">
            <table className="wishlist">
                <thead className="wishlist__head">
                    <tr className="wishlist__row">
                        <th className="wishlist__column wishlist__column--image">Изображение</th>
                        <th className="wishlist__column wishlist__column--product">Продукт</th>
                        <th className="wishlist__column wishlist__column--stock">Статус</th>
                        <th className="wishlist__column wishlist__column--price">Стоимость</th>
                        <th className="wishlist__column wishlist__column--tocart"></th>
                        <th className="wishlist__column wishlist__column--remove"></th>
                    </tr>
                </thead>
                <tbody className="wishlist__body">
                    <WishListTr/>
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default WishListContainer