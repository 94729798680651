import React from 'react'

const AddressEdit = () => {
  return (
    <div className="card">
      <div className="card-header">
          <h5>Редактировать адрес</h5>
      </div>
      <div className="card-divider"></div>
      <div className="card-body">
          <div className="row no-gutters">
              <div className="col-12 col-lg-10 col-xl-8">
                  <div className="form-row">
                      <div className="form-group col-md-6">
                          <label >Имя</label>
                          <input type="text" className="form-control" id="checkout-first-name" placeholder="Введите имя"/>
                      </div>
                      <div className="form-group col-md-6">
                          <label >Фамилия</label>
                          <input type="text" className="form-control" id="checkout-last-name" placeholder="Введите фамилию"/>
                      </div>
                  </div>
                  <div className="form-group">
                      <label >Название компании <span className="text-muted">(Optional)</span></label>
                      <input type="text" className="form-control" id="checkout-company-name" placeholder="Введите название компании"/>
                  </div>
                  <div className="form-group">
                      <label >Страна</label>
                      <select id="checkout-country" className="form-control form-control-select2">
                          <option>Выберите страну...</option>
                          <option>Соединенные Штаты Америки</option>
                          <option>Россия</option>
                          <option>Италия</option>
                          <option>Франция</option>
                          <option>Украина</option>
                          <option>Германия</option>
                          <option>Автралия</option>
                      </select>
                  </div>
                  <div className="form-group">
                      <label >Адрес</label>
                      <input type="text" className="form-control" id="checkout-street-address" placeholder="введите улицу..."/>
                  </div>
                  <div className="form-group">
                      <label >Квартира, апартаменты, дом и тд. <span className="text-muted">(Optional)</span></label>
                      <input type="text" className="form-control" id="checkout-address"/>
                  </div>
                  <div className="form-group">
                      <label >Город</label>
                      <input type="text" className="form-control" id="checkout-city"/>
                  </div>
                  <div className="form-group">
                      <label >Штат / Область</label>
                      <input type="text" className="form-control" id="checkout-state"/>
                  </div>
                  <div className="form-group">
                      <label >Почтовый индекс / ZIP</label>
                      <input type="text" className="form-control" id="checkout-postcode"/>
                  </div>
                  <div className="form-row">
                      <div className="form-group col-md-6">
                          <label >Адрес электронной почты</label>
                          <input type="email" className="form-control" id="checkout-email" placeholder="Введите адрес электронной почты"/>
                      </div>
                      <div className="form-group col-md-6">
                          <label >Номер телефона</label>
                          <input type="text" className="form-control" id="checkout-phone" placeholder="Введите номер телефона"/>
                      </div>
                  </div>
                  <div className="form-group mt-3 mb-0">
                      <button className="btn btn-primary">Сохранить</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default AddressEdit