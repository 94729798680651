import React from 'react'

const TextComponent = ({item}) => {
  return (
    <div className='container'>
        <div dangerouslySetInnerHTML={{__html: item.description}}></div>
    </div>
  )
}

export default TextComponent