import React, { useContext } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Error = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2) {
                navigate(`/${app.lang}/error`)
            }
        }
    }, [app.lang])

  return (
    <div className="block">
        <div className="container">
            <div className="not-found">
                <div className="not-found__404">
                    {app.localizationsItems?.error_title}
                </div>
                <div className="not-found__content">
                    <h1 className="not-found__title">{app.localizationsItems?.error_not_fount}</h1>
                    <p className="not-found__text">
                        {app.localizationsItems?.error_content}
                    </p>
                    <NavLink className="btn btn-secondary btn-sm" to={`/${app.lang}`}>{app.localizationsItems?.to_main}</NavLink>
                </div>
            </div>
        </div>
    </div>
  )
})

export default Error