import React, { useState } from 'react'
import { Link} from 'react-router-dom'

const CategoriesItem2 = ({it, parent, slug}) => {
    const [open, setOpen] = useState();

  return (
    <>
        {it.elements?.length >= 1 ?
            <li 
                className={(open && (slug === it.slug)) ? 
                    "filter-categories-alt__item filter-categories-alt__item--open filter-categories__item--current" : 
                    (slug === it.slug) ?
                    'filter-categories-alt__item filter-categories__item--current'
                    :
                    open ?
                    'filter-categories-alt__item filter-categories-alt__item--open'
                    :
                    'filter-categories-alt__item'
                }
                
            >
                <button className="filter-categories-alt__expander" onClick={() => setOpen(!open)}></button>
                <Link to={parent ? `${parent}/${it.slug}` : it.slug} className={slug === it.slug ? 'link-bold' : ''}>{it.title}</Link>
                <div className="filter-categories-alt__children" >
                    <ul className="filter-categories-alt__list filter-categories-alt__list--level--2">
                        {it.elements.map((elem, index) =>
                            <CategoriesItem2 it={elem} key={index} parent={parent ? `${parent}/${it.slug}` : `${it.slug}`} slug={slug}/>
                        )}
                    </ul>
                </div>
            </li>
            :
            <li className={slug === it.slug ? "filter-categories-alt__item filter-categories__item--current" : 'filter-categories-alt__item'}>
                <Link to={parent ? `${parent}/${it.slug}` : it.slug} className={slug === it.slug ? 'link-bold' : ''}>{it.title}</Link>
            </li>
        }
    </>
  )
}

export default CategoriesItem2