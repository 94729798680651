import React from 'react'

const BreadcrumbTitle = () => {
  return (
    <div className="page-header">
        <div className="page-header__container container">
            <div className="page-header__breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="index.html">Главная</a>
                            <svg className="breadcrumb-arrow" width="6px" height="9px">
                                <use href="images/sprite.svg#arrow-rounded-right-6x9"></use>
                            </svg>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="">Breadcrumb</a>
                            <svg className="breadcrumb-arrow" width="6px" height="9px">
                                <use href="images/sprite.svg#arrow-rounded-right-6x9"></use>
                            </svg>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Мой Аккаунт</li>
                    </ol>
                </nav>
            </div>
            <div className="page-header__title">
                <h1>Мой Аккаунт</h1>
            </div>
        </div>
    </div>
  )
}

export default BreadcrumbTitle