import React, { useContext } from 'react'
import OfferItem from '../simple/OfferItem'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const OfferGrid = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
    } = useFetchItems(CatalogServices.products.index, `type=sale`)

    const {
        items:populars,
    } = useFetchItems(CatalogServices.products.index, `type=popular`)

    const {
        items:bestsellers,
    } = useFetchItems(CatalogServices.products.index, `type=bestsellers`)

  return (
    <div className="block block-product-columns d-lg-block d-none">
        <div className="container">
            <div className="row">
                <div className="col-4">
                    <div className="block-header">
                        <h3 className="block-header__title">{app.localizationsItems?.popular_products}</h3>
                        <div className="block-header__divider"></div>
                    </div>
                    <div className="block-product-columns__column">
                        {populars?.items?.slice(0,3).map((card, index) =>
                            <OfferItem card={card} key={index}/>
                        )}
                    </div>
                </div>
                <div className="col-4">
                    <div className="block-header">
                        <h3 className="block-header__title">{app.localizationsItems?.special_offers}</h3>
                        <div className="block-header__divider"></div>
                    </div>
                    <div className="block-product-columns__column">
                        {items?.items?.slice(0,3).map((card, index) =>
                            <OfferItem card={card} key={index}/>
                        )}
                    </div>
                </div>
                <div className="col-4">
                    <div className="block-header">
                        <h3 className="block-header__title">{app.localizationsItems?.bestsellers}</h3>
                        <div className="block-header__divider"></div>
                    </div>
                    <div className="block-product-columns__column">
                        {bestsellers?.items?.slice(0,3).map((card, index) =>
                            <OfferItem card={card} key={index}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default OfferGrid