import React, { useContext } from 'react'
import LatestItem from '../simple/LatestItem'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const LatestProducts = observer(({items}) => {
    const {app} = useContext(Context);
    
  return (
    <div className="block-sidebar__item d-none d-lg-block">
        <div className="widget-products widget">
            <h4 className="widget__title">{app.localizationsItems?.latest_products}</h4>
            <div className="widget-products__list">
                {items?.items?.slice(0,5).map((card, index) => 
                    <LatestItem card={card} key={index}/>
                )}
            </div>
        </div>
    </div>
  )
})

export default LatestProducts