import React, { useContext } from 'react'
import ProductCard from '../simple/ProductCard'
import CatalogServices from '../../services/general/catalogServices'
import useFetchItems from '../../hooks/useFetchItems'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const SimilarProducts = observer(({ids}) => {
    const {app} = useContext(Context);

    const {
        items,
    } = useFetchItems(CatalogServices.products.index, ids)

    const settings = {
        items: 4,
        margin: 14,
        nav: false,
        dots: false,
        loop: true,
        stagePadding: 1,
        responsive: {
            1200: {items: 5, margin: 12},
            992:  {items: 4, margin: 10},
            768:  {items: 3, margin: 10},
        }
    };

  return (
    <div className="block block-products-carousel" data-layout="grid-5" data-mobile-grid-columns="2">
        <div className="container">
            <div className="block-header">
                <h3 className="block-header__title">{app.localizationsItems?.related_products}</h3>
                <div className="block-header__divider"></div>
                <div className="block-header__arrows-list">
                    <button className="block-header__arrow block-header__arrow--left" type="button">
                        <i className='fa fa-chevron-left'></i>
                    </button>
                    <button className="block-header__arrow block-header__arrow--right" type="button">
                        <i className='fa fa-chevron-right'></i>
                    </button>
                </div>
            </div>
            <div className="block-products-carousel__slider">
                <div className="block-products-carousel__preloader"></div>
                {items?.items && 
                    <OwlCarousel {...settings}>
                        {items.items.map((card, index) =>
                            <div className="block-products-carousel__column" key={index}>
                                <div className="block-products-carousel__cell">
                                    <ProductCard card={card}/>
                                </div>
                            </div>
                        )}
                    </OwlCarousel>
                }
            </div>
        </div>
    </div>
  )
})

export default SimilarProducts