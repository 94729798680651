import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"

const MainBanner = ({elem}) => {
    const settings = {
        items: 1,
        nav: false,
        dots: true,
        loop: true
    }

  return (
    <div className="block-slideshow block-slideshow--layout--full block">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="block-slideshow__body">
                        <OwlCarousel className="owl-carousel" {...settings}>
                            {elem.elements?.map((card, index) =>
                                <NavLink className="block-slideshow__slide" key={index} to={card.link} 
                                    style={card.banner_font_color ? {color: card.banner_font_color} : {}}
                                >
                                    <div className={card.mob_image ? "block-slideshow__slide-image block-slideshow__slide-image--desktop" : "block-slideshow__slide-image"}>
                                        <img src={card.front_image ? card.front_image : img} 
                                            height='440'
                                            alt={card.title}
                                            title={card.title}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src = img
                                                }}
                                        />
                                    </div>
                                    {card.mob_image && <div className="block-slideshow__slide-image block-slideshow__slide-image--mobile">
                                        <img src={card.mob_image} alt={card.title} title={card.title}/>
                                    </div>}
                                    <div className="block-slideshow__slide-content">
                                        <h1 className="block-slideshow__slide-title">{card.title}</h1>
                                        <div className="block-slideshow__slide-text"
                                            dangerouslySetInnerHTML={{ __html: card.description }}   
                                        >   
                                        </div>
                                        <div className="block-slideshow__slide-button">
                                            <span className="btn btn-primary btn-lg">{card.button_text}</span>
                                        </div>
                                    </div>
                                </NavLink>
                            )}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MainBanner